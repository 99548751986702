import CardService from '../CardService/CardService.vue';
import ApiServices from '@/services/ApiServices';
import { mapState } from 'vuex';
export default {
	name: 'SectionServices',
	components: {
		CardService,
	},
	data() {
		return {
			services: [],
		};
	},
	methods: {
		async getServices() {
			try {
				const result = await ApiServices.getServices(this.$store.state.language);
				this.services = result;
			} catch (error) {
				console.log('Error get services ' + error);
			}
		},
	},
	computed: {
		...mapState({
			language: (state) => state.language,
		}),
	},
	watch: {
		language: {
			handler() {
				this.getServices();
			},
		},
	},
	mounted() {
		this.getServices();
	},
};
