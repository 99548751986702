import ApiServices from '@/services/ApiServices';
import ApiServicesAdmin from '@/services/ApiServicesAdmin';
import alerts from '@/utilities/alerts';
export default {
	name: 'VideoPresentationManagement',
	data() {
		return {
			title: 'Actualizar Video De Presentación',
			video: [],
			dialog: false,
			data: {
				_id: '',
				video: '',
			},
			validateButton: true,
		};
	},
	methods: {
		async getVideoPresentation() {
			try {
				const data = await ApiServices.getVideoPresentation();
				this.video = data;
			} catch (error) {
				console.log('Error get video presentation ' + error);
			}
		},
		closeModal() {
			this.dialog = false;
		},
		async save() {
			try {
				const formData = new FormData();
				const { ...values } = { ...this.data };
				formData.append('_id', values._id);
				formData.append('video', values.video);
				const result = await ApiServicesAdmin.updateVideoPresentation(formData);
				if (result.result) {
					alerts.showAlertUpdate(result.result);
					this.closeModal();
				}
			} catch (error) {
				alerts.showAlertUpdate(result.result);
				this.closeModal();
			}
		},
		editVideo(value) {
			this.dialog = true;
			this.data._id = value;
		},
		getPathVideo(video) {
			if (video === '' || video === null) {
				this.validateButton = true;
			} else {
				this.data.video = video;
				this.validateButton = false;
			}
		},
	},
	watch: {
		video(newVal, oldVal) {
			this.getVideoPresentation();
		},
	},
	mounted() {
		this.getVideoPresentation();
	},
};
