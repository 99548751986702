import ApiServices from '@/services/ApiServicesAdmin';
import alerts from '@/utilities/alerts';

export default {
	name: 'ImagesHomeManagement',
	data() {
		return {
			title: 'Gestión Imagenes Inicio',
			images: [],
			dialog: false,
			data: {
				image: '',
			},
			validateButton: true,
		};
	},
	methods: {
		async getImages() {
			try {
				const data = await ApiServices.getImagesHome();
				this.images = data;
			} catch (error) {
				console.log('Error get images ' + error);
			}
		},
		closeModal() {
			this.dialog = false;
		},
		async save() {
			try {
				const { ...values } = { ...this.data };
				const formData = new FormData();
				formData.append('image', values.image);
				const result = await ApiServices.addImageHome(formData);
				alerts.showAlertAdd(result.result);
				this.closeModal();
			} catch (error) {
				console.log('Error add image ' + error);
				this.closeModal();
			}
		},
		async deleteImage(value) {
			try {
				const result = await ApiServices.deleteImageHome(value);
				alerts.showAlertDelete(result.result);
			} catch (error) {
				console.log('Error delete image ' + error);
			}
		},

		getPathImage(image) {
			if (image === '' || image === null) {
				this.validateButton = true;
			} else {
				this.data.image = image;
				this.validateButton = false;
			}
		},
	},
	watch: {
		images(newVal, oldVal) {
			this.getImages();
		},
	},
	mounted() {
		this.getImages();
	},
};
