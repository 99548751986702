import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import EncriptPassword from '@/utilities/EncriptPassword';
import { mapState } from 'vuex';

Vue.use(Vuex);
const path = 'https://icnbakersfield.com:3336';
export default new Vuex.Store({
	state: {
		language: 'spanish',
		typeUser: 'user',
		isLoggedIn: false,
		accessToken: '',
	},
	getters: {},
	mutations: {
		setLanguage(state, language) {
			state.language = language;
		},
		setTypeUser(state, typeUser) {
			state.typeUser = typeUser;
		},
		SET_LOGGED_IN(state, value) {
			state.isLoggedIn = value;
		},
		SET_ACCESS_TOKEN(state, token) {
			state.accessToken = token;
		},
	},
	actions: {
		async logIn({ commit }, { email, password }) {
			const passwordEncrypt = EncriptPassword.encriptPassword(password);
			const user = {
				email,
				password: passwordEncrypt,
			};
			const { data } = await axios.post(`${path}/administrator/login`, user);
			if (data.authenticated) {
				commit('SET_ACCESS_TOKEN', data.token);
				commit('SET_LOGGED_IN', true);
			} else {
				throw new Error('Incorrect');
			}
		},
		logOut({ commit }) {
			commit('setTypeUser', 'user');
			commit('SET_LOGGED_IN', false);
			commit('SET_ACCESS_TOKEN', '');
		},
	},
	modules: {},
});
