import ApiServices from '@/services/ApiServicesAdmin';
import alerts from '@/utilities/alerts';

export default {
	name: 'Missions',
	data() {
		return {
			title: 'Gestión Valores Centrales',
			values: [],
			dialog: false,
			dialogDelete: false,
			headers: [
				{ text: 'Inglés', value: 'value.english' },
				{ text: 'Español', value: 'value.spanish' },
				{ text: 'Acciones', value: 'actions', sortable: false },
			],
			editedIndex: -1,
			editedItem: {
				_id: '',
				value: [
					{
						english: '',
						spanish: '',
					},
				],
			},
			defaultItem: {
				_id: '',
				value: [
					{
						english: '',
						spanish: '',
					},
				],
			},
		};
	},
	methods: {
		async getCoreValues() {
			try {
				const data = await ApiServices.getCoreValues();
				this.values = data;
			} catch (error) {
				console.log('Error get core values ' + error);
			}
		},
		async save() {
			try {
				if (this.editedIndex > -1) {
					const values = JSON.parse(JSON.stringify(this.editedItem));
					const result = await ApiServices.updateCoreValue(values);
					alerts.showAlertUpdate(result.result);
				} else {
					const values = {
						value: {
							spanish: this.editedItem.value.spanish,
							english: this.editedItem.value.english,
						},
					};
					const result = await ApiServices.addCoreValue(values);
					alerts.showAlertAdd(result.result);
				}
				this.close();
			} catch (error) {
				console.log('Error save new core value ' + error);
			}
		},
		editItem(item) {
			const result = JSON.parse(JSON.stringify(item));
			this.editedIndex = this.values.indexOf(item);
			this.editedItem = Object.assign({}, result);
			this.dialog = true;
		},
		close() {
			this.dialog = false;
			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem);
				this.editedIndex = -1;
			});
		},
		closeDelete() {
			this.dialogDelete = false;
			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem);
				this.editedIndex = -1;
			});
		},
		deleteItem(item) {
			const result = JSON.parse(JSON.stringify(item));
			this.editedIndex = this.values.indexOf(item);
			this.editedItem = Object.assign({}, result);
			this.dialogDelete = true;
		},
		async deleteItemConfirm() {
			const values = JSON.parse(JSON.stringify(this.editedItem));
			const result = await ApiServices.deleteCoreValue(values._id);
			alerts.showAlertDelete(result.result);
			this.closeDelete();
		},
		activeButton() {
			if (this.validateValueSpanish && this.validateValueEnglish) {
				return false;
			}
			return true;
		},
	},
	computed: {
		formTitle() {
			return this.editedIndex === -1 ? 'Agregar Valor Central' : 'Editar Valor Central';
		},
		validateValueSpanish() {
			if (typeof this.editedItem.value.spanish === 'undefined') {
				return false;
			} else {
				return /^[A-Za-zñÑáéíóúÁÉÍÓÚ\s]{5,150}$/.test(this.editedItem.value.spanish);
			}
		},
		validateValueEnglish() {
			if (typeof this.editedItem.value.english === 'undefined') {
				return false;
			} else {
				return /^[A-Za-z\s]{5,150}$/.test(this.editedItem.value.english);
			}
		},
	},
	watch: {
		values(newVal, oldVal) {
			this.getCoreValues();
		},
		dialog(val) {
			val || this.close();
		},
		dialogDelete(val) {
			val || this.closeDelete();
		},
	},
	mounted() {
		this.getCoreValues();
	},
	created() {
		this.getCoreValues();
	},
};
