<template>
	<div class="group-life">
		<SectionGroupLife />
	</div>
</template>
<script>
	import SectionGroupLife from '@/components/user/SectionGroupLife/SectionGroupLife.vue';
	export default {
		name: 'GroupLifeView',
		components: {
			SectionGroupLife,
		},
		data() {
			return {};
		},
	};
</script>
