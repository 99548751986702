import { mapState } from 'vuex';
import { ref } from 'vue';

export default {
	name: 'CardDonation',
	props: {
		_id: String,
		title: String,
		subtitle: String,
		amounts: Array,
		show: Boolean,
		data: {
			type: Object,
			default: () => ({
				_id: '',
				title: {
					spanish: '',
					english: '',
				},
				subtitle: {
					spanish: '',
					english: '',
				},
				amounts: {},
				show: true,
			}),
		},
	},
	data() {
		return {
			logo: ref(require('@/assets/logoPayPal.png')).value,
			modelAmount: '',
			urlDonation: '',
			showInput: false,
			inputAmount: 0,
			labelButton: {
				spanish: 'DONAR',
				english: 'DONATE',
			},
			selectedLableButton: {},
			labelOther: {
				spanish: {
					label: 'Cantidad',
					title: 'OTRO',
				},
				english: {
					label: 'Amount',
					title: 'OTHER',
				},
			},
			selectLabelOther: {},
		};
	},
	methods: {
		editCard() {
			this.$emit('edit', this.data);
		},
		showCard() {
			this.$emit('show', this.data._id);
		},
		confirmDonation() {
			var amount = this.modelAmount;
			if (this.inputAmount != 0) {
				amount = this.inputAmount;
			}
			this.$emit('confirmDonation', amount);
		},
		selectLabelButton() {
			try {
				const language = this.$store.state.language;
				this.selectedLableButton = this.labelButton[language];
			} catch (error) {
				console.log('Error select label button by language ' + error);
			}
		},
		selectLableOther() {
			try {
				const language = this.$store.state.language;
				this.selectLabelOther = this.labelOther[language];
			} catch (error) {
				console.log('Error select label button other amount ' + error);
			}
		},
	},
	computed: {
		...mapState({
			language: (state) => state.language,
		}),
	},
	watch: {
		language: {
			handler() {
				this.selectLabelButton();
				this.selectLableOther();
			},
		},
		modelAmount(newVal, oldVal) {
			this.inputAmount = 0;
			this.showInput = newVal === 0 ? true : false;
		},
	},
	mounted() {
		this.selectLabelButton();
		this.selectLableOther();
	},
};
