import ApiServices from '@/services/ApiServices';
import ApiServicesAdmin from '@/services/ApiServicesAdmin';
import { mapState } from 'vuex';
export default {
	name: 'SectionEvents',
	data() {
		return {
			liveEvent: [],
			images: [],
			events: [],
			today: '',
			titlesSections: {
				spanish: {
					important: 'EVENTOS IMPORTANTES',
					calendar: 'CALENDARIO DE EVENTOS',
					live: 'EVENTO EN VIVO',
				},
				english: {
					important: 'IMPORTANT EVENTS',
					calendar: 'CALENDAR EVENTS',
					live: 'LIFE EVENT',
				},
			},
			titlesSelecteds: [],
		};
	},
	methods: {
		async getImagesEvents() {
			try {
				const data = await ApiServicesAdmin.getImagesEvents(this.$store.state.language);
				this.images = data;
			} catch (error) {
				console.log('Error get images events ' + error);
			}
		},
		async getEvents() {
			try {
				const data = await ApiServices.getEvents(this.$store.state.language);
				this.events = data.map(({ _id, ...event }) => event);
			} catch (error) {
				console.log('Error get events ' + error);
			}
		},
		async getLiveEvent() {
			try {
				const data = await ApiServices.getLiveEvent();
				this.liveEvent = data[0];
			} catch (error) {
				console.log('Error get live event ' + error);
			}
		},
		getNowDate() {
			const now = new Date();
			const year = now.getFullYear();
			const month = now.getMonth() + 1;
			const day = now.getDate();
			this.today = `${year}-${month.toString().padStart(2, '0')}-${day
				.toString()
				.padStart(2, '0')}`;
		},
		selectTitlesByLanguage() {
			try {
				const language = this.$store.state.language;
				this.titlesSelecteds = this.titlesSections[language];
			} catch (error) {
				console.log('Error select title by language ' + error);
			}
		},
	},
	computed: {
		...mapState({
			language: (state) => state.language,
		}),
	},
	watch: {
		language: {
			handler() {
				this.getImagesEvents();
				this.getEvents();
				this.selectTitlesByLanguage();
			},
		},
		liveEvent(newValue, oldValue) {
			this.getLiveEvent();
		},
	},
	mounted() {
		this.$refs.calendar.scrollToTime('08:00');
		this.getImagesEvents();
		this.getEvents();
		this.getLiveEvent();
		this.selectTitlesByLanguage();
	},
	created() {
		this.getNowDate();
	},
};
