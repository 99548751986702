import axios from 'axios';
import store from '../store/index';
import { setAxiosInterceptors } from '@/axios';
const path = 'https://icnbakersfield.com:3336';
setAxiosInterceptors();
export default {
	async getAdministrator() {
		const { data } = await axios.get(`${path}/administrator/`);
		return data[0];
	},
	async updateVideoPresentation(infor) {
		const { data } = await axios.put(`${path}/admin-video-presentation/`, infor);
		return data;
	},
	async getDataCardRegister() {
		const { data } = await axios.get(`${path}/admin-cards-register/`);
		return data;
	},
	async updateCardRegister(infor) {
		const { data } = await axios.put(`${path}/admin-cards-register/`, infor);
		return data;
	},
	async getImagesHome() {
		const { data } = await axios.get(`${path}/images-home/`);
		return data;
	},
	async addImageHome(infor) {
		const { data } = await axios.post(`${path}/admin-images-home/`, infor);
		return data;
	},
	async deleteImageHome(id) {
		const { data } = await axios.delete(`${path}/admin-images-home/${id}`);
		return data;
	},
	async getSongs() {
		const { data } = await axios.get(`${path}/songs/`);
		return data;
	},
	async addSong(infor) {
		const { data } = await axios.post(`${path}/admin-songs/`, infor);
		return data;
	},
	async deleteSong(id) {
		const { data } = await axios.delete(`${path}/admin-songs/${id}`);
		return data;
	},
	async getMissions() {
		const { data } = await axios.get(`${path}/admin-missions/`);
		return data;
	},
	async addMission(infor) {
		const { data } = await axios.post(`${path}/admin-missions/`, infor);
		return data;
	},
	async updateMission(infor) {
		const { data } = await axios.put(`${path}/admin-missions/${infor._id}`, infor.mission);
		return data;
	},
	async deleteMission(id) {
		const { data } = await axios.delete(`${path}/admin-missions/${id}`);
		return data;
	},
	async getCoreValues() {
		const { data } = await axios.get(`${path}/admin-core-values/`);
		return data;
	},
	async addCoreValue(infor) {
		const { data } = await axios.post(`${path}/admin-core-values/`, infor);
		return data;
	},
	async updateCoreValue(infor) {
		const { data } = await axios.put(`${path}/admin-core-values/${infor._id}`, infor.value);
		return data;
	},
	async deleteCoreValue(id) {
		const { data } = await axios.delete(`${path}/admin-core-values/${id}`);
		return data;
	},
	async getDataPastoralTeam() {
		const { data } = await axios.get(`${path}/admin-pastoral-team/`);
		return data;
	},
	async getDataPastoralSenior() {
		const { data } = await axios.get(`${path}/admin-pastoral-team/senior`);
		return data;
	},
	async addPastoralUser(infor) {
		const { data } = await axios.post(`${path}/admin-pastoral-team/`, infor);
		return data;
	},
	async updatePastoralUser(infor) {
		const { data } = await axios.put(`${path}/admin-pastoral-team/`, infor);
		return data;
	},
	async updateSeniorPastoral(infor) {
		const { data } = await axios.put(`${path}/admin-pastoral-team/senior`, infor);
		return data;
	},
	async deletePastoralUser(id) {
		const { data } = await axios.delete(`${path}/admin-pastoral-team/${id}`);
		return data;
	},
	async addSocialNetwork(infor) {
		const { data } = await axios.post(`${path}/admin-social-network/`, infor);
		return data;
	},
	async updateSocialNetwork(infor) {
		const { data } = await axios.put(`${path}/admin-social-network/${infor._id}`, infor);
		return data;
	},
	async deleteSocialNetwork(id) {
		const { data } = await axios.delete(`${path}/admin-social-network/${id}`);
		return data;
	},
	async getImagesEvents(language) {
		const { data } = await axios.get(`${path}/image-event/${language}`);
		return data;
	},
	async getImagesEventsAdmin() {
		const { data } = await axios.get(`${path}/admin-image-event/`);
		return data;
	},
	async addImagesEvents(infor) {
		const { data } = await axios.post(`${path}/admin-image-event/`, infor);
		return data;
	},
	async deleteImagesEvents(id) {
		const { data } = await axios.delete(`${path}/admin-image-event/${id}`);
		return data;
	},
	async getEvents() {
		const { data } = await axios.get(`${path}/admin-events/`);
		return data;
	},
	async addEventToCalendar(infor) {
		const { data } = await axios.post(`${path}/admin-events/`, infor);
		return data;
	},

	async updateEventToCalendar(infor) {
		const { data } = await axios.put(`${path}/admin-events/${infor._id}`, infor);
		return data;
	},
	async deleteEventToCalendar(id) {
		const { data } = await axios.delete(`${path}/admin-events/${id}`);
		return data;
	},
	async updateLiveEvent(infor) {
		const { data } = await axios.put(`${path}/admin-live-event/${infor._id}`, infor);
		return data;
	},
	async getServices() {
		const { data } = await axios.get(`${path}/admin-services/`);
		return data;
	},
	async addService(infor) {
		const { data } = await axios.post(`${path}/admin-services/`, infor);
		return data;
	},
	async updateService(infor) {
		const { data } = await axios.put(`${path}/admin-services/${infor._id}`, infor);
		return data;
	},
	async deleteService(id) {
		const { data } = await axios.delete(`${path}/admin-services/${id}`);
		return data;
	},
	async getUsersVisit() {
		const { data } = await axios.get(`${path}/register/visit`);
		return data;
	},
	async getUsersReceived() {
		const { data } = await axios.get(`${path}/register/received`);
		return data;
	},
	async getUsersPetition() {
		const { data } = await axios.get(`${path}/register/petition`);
		return data;
	},
	async getUsersGroupLife() {
		const { data } = await axios.get(`${path}/register/group-life`);
		return data;
	},
	async updateUser(id) {
		const { data } = await axios.put(`${path}/register/${id}`);
		return data;
	},
	async getGroupsLife() {
		const { data } = await axios.get(`${path}/group-life/`);
		return data;
	},
	async addGroupLife(infor) {
		const { data } = await axios.post(`${path}/group-life/`, infor);
		return data;
	},
	async updateGroupLife(infor) {
		const { data } = await axios.put(`${path}/group-life/`, infor);
		return data;
	},
	async deleteGroupLife(id) {
		const { data } = await axios.delete(`${path}/group-life/${id}`);
		return data;
	},
	async getDataCardDonation() {
		const { data } = await axios.get(`${path}/donation/`);
		return data[0];
	},
	async updateCardDonation(infor) {
		const { data } = await axios.put(`${path}/donation/`, infor);
		return data;
	},
	async showCardDonation(id) {
		const { data } = await axios.put(`${path}/donation/show/${id}`);
		return data;
	},
	async getDonationQR() {
		const { data } = await axios.get(`${path}/donation-qr/`);
		return data[0];
	},
	async updateCardDonationQR(infor) {
		const { data } = await axios.put(`${path}/donation-qr/`, infor);
		return data;
	},
	async getDonationWays() {
		const { data } = await axios.get(`${path}/donation-way`);
		return data;
	},
	async updateDonationWay(infor) {
		const { data } = await axios.put(`${path}/donation-way/`, infor);
		return data;
	},
	async getDetailsRegisters() {
		const { data } = await axios.get(`${path}/register/details`);
		return data;
	},
	async getGeneralInformation() {
		const { data } = await axios.get(`${path}/footer/`);
		return data[0];
	},
	async updateGeneralInformation(infor) {
		const { data } = await axios.put(`${path}/footer/`, infor);
		return data;
	},
	async updateAdministratorInformation(infor) {
		const { data } = await axios.put(`${path}/administrator/`, infor);
		return data;
	},
	async getPasswordAdminitrator() {
		const { data } = await axios.get(`${path}/administrator/password`);
		return data[0];
	},
	async updatePasswordAdministrator(infor) {
		const { data } = await axios.put(`${path}/administrator/password/${infor.id}`, infor);
		return data;
	},
};
