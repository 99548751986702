import ApiServices from '@/services/ApiServicesAdmin';
const Swal = require('sweetalert2');

export default {
	name: 'SongManagement',
	data() {
		return {
			title: 'Gestión Predicaciones',
			songs: [],
			dialog: false,
			data: {
				title: '',
				song: '',
			},
		};
	},
	methods: {
		async getSongs() {
			try {
				const data = await ApiServices.getSongs();
				this.songs = data;
			} catch (error) {
				console.log('Error get songs ' + error);
			}
		},
		close() {
			this.dialog = false;
		},
		async save() {
			try {
				const { ...values } = { ...this.data };
				const formData = new FormData();
				formData.append('_id', values._id);
				formData.append('title', values.title);
				formData.append('song', values.song);
				const data = await ApiServices.addSong(formData);
				if (data.result) {
					Swal.fire({
						position: 'top-end',
						icon: 'success',
						title: 'Agregado correctamente',
						showConfirmButton: false,
						timer: 1500,
					});
					this.close();
				}
			} catch (error) {
				Swal.fire({
					position: 'top-end',
					icon: 'error',
					title: 'Error al agregar',
					showConfirmButton: false,
					timer: 1500,
				});
				console.log('Error ' + error);
			}
		},
		async deleteSong(value) {
			try {
				const result = await ApiServices.deleteSong(value);
				if (result.result) {
					Swal.fire({
						position: 'top-end',
						icon: 'success',
						title: 'Eliminado correctamente',
						showConfirmButton: false,
						timer: 1500,
					});
				}
			} catch (error) {
				console.log('Error delete song ' + error);
			}
		},

		getPathSong(song) {
			this.data.song = song;
		},
	},
	watch: {
		songs(newVal, oldVal) {
			this.getSongs();
		},
	},
	mounted() {
		this.getSongs();
	},
};
