import store from '@/store';
import LoginView from '@/views/admin/auth/LoginView.vue';
import Vue from 'vue';
import VueRouter from 'vue-router';

import ApplicationAdminView from '../views/admin/ApplicationAdminView.vue';
import VideoPresentationManagement from '../views/admin/VideoPresentationManagement/VideoPresentationManagement.vue';
import CardRegisterManagement from '../views/admin/CardRegisterManagement/RegisterManagement.vue';
import ImagesManagement from '../views/admin/ImagesHomeManagement/ImagesHomeManagement.vue';
import SongManagement from '@/views/admin/SongManagement/SongManagement.vue';
import MissionsManagement from '../views/admin/MissionsManagement/MissionsManagement.vue';
import CoreValuesManagement from '../views/admin/CoreValuesManagement/CoreValuesManagement.vue';
import PastoralManagement from '../views/admin/PastoralTeamManagement/PastoralTeamManagement.vue';
import SeniorPastorManagement from '@/views/admin/SeniorPastorManagement/SeniorPastorManagement.vue';
import SocialNetworkManagement from '@/views/admin/SocialNetworkManagement/SocialNetworkManagement.vue';
import AboutUserView from '../views/user/AboutUserView.vue';
import HomeUserView from '../views/user/HomeUserView.vue';
import EventUserView from '../views/user/EventUserView.vue';
import GroupLifeView from '../views/user/GroupLifeView.vue';
import ImportantEventManagement from '@/views/admin/ImportantEventManagement/ImportantEventManagement.vue';
import CalendarEventManagement from '@/views/admin/CalendarEventManagement/CalendarEventManagement.vue';
import LiveEventManagement from '@/views/admin/LiveEventManagement/LiveEventManagement.vue';
import SectionServices from '@/components/user/SectionServices/SectionServices.vue';
import ServicesManagement from '@/views/admin/ServicesManagement/ServicesManagement.vue';
import UserVisitManagement from '@/views/admin/UsersManagement/UserVisitManagement.vue';
import UserReceivedManagement from '@/views/admin/UsersManagement/UserReceivedManagement.vue';
import UserPetitionManagement from '@/views/admin/UsersManagement/UserPetitionManagement.vue';
import UserGroupLifeManagement from '@/views/admin/UsersManagement/UserGroupLifeManagement.vue';
import GroupLifeManagement from '@/views/admin/GroupLifeManagement/GroupLifeManagement.vue';
import DonationView from '@/views/user/DonationView.vue';
import CardDonationPaypalManagement from '@/views/admin/CardDonationPaypalManagement/CardDonationPaypalManagement.vue';
import CardDonationQRManagement from '@/views/admin/CardDonationQRManagement/CardDonationQRManagement.vue';
import CardDonationWayManagement from '@/views/admin/CardDonationWayManagement/CardDonationWayManagement.vue';

Vue.use(VueRouter);

const routes = [
	{
		path: '/',
		name: 'home',
		component: HomeUserView,
	},
	{
		path: '/login',
		name: 'login',
		component: LoginView,
	},
	{
		path: '/admin',
		name: 'admin',
		component: ApplicationAdminView,
		meta: { requiresAuth: true },
	},
	{
		path: '/about',
		name: 'about',
		component: AboutUserView,
	},
	{
		path: '/events',
		name: 'event',
		component: EventUserView,
	},
	{
		path: '/services',
		name: 'services',
		component: SectionServices,
	},
	{
		path: '/group-life',
		name: 'groupLife',
		component: GroupLifeView,
	},
	{
		path: '/donations',
		name: 'donation',
		component: DonationView,
	},
	{
		path: '/video-presentation-management',
		name: 'videoPresentation',
		component: VideoPresentationManagement,
		meta: { requiresAuth: true },
	},
	{
		path: '/card-register-management',
		name: 'cardManagement',
		component: CardRegisterManagement,
		meta: { requiresAuth: true },
	},
	{
		path: '/images-home-management',
		name: 'imagesHomeManagement',
		component: ImagesManagement,
		meta: { requiresAuth: true },
	},
	{
		path: '/songs-management',
		name: 'songsManagement',
		component: SongManagement,
		meta: { requiresAuth: true },
	},
	{
		path: '/mission',
		name: 'missions',
		component: MissionsManagement,
		meta: { requiresAuth: true },
	},
	{
		path: '/core-values',
		name: 'coreValues',
		component: CoreValuesManagement,
		meta: { requiresAuth: true },
	},
	{
		path: '/pastoral-team-management',
		name: 'pastoralManagement',
		component: PastoralManagement,
		meta: { requiresAuth: true },
	},
	{
		path: '/senior-pastoral-management',
		name: 'seniorPastoralManagement',
		component: SeniorPastorManagement,
		meta: { requiresAuth: true },
	},
	{
		path: '/social-network-management',
		name: 'socialNetworkManagement',
		component: SocialNetworkManagement,
		meta: { requiresAuth: true },
	},
	{
		path: '/important-events-management',
		name: 'importantEventManagement',
		component: ImportantEventManagement,
		meta: { requiresAuth: true },
	},
	{
		path: '/calendar-events-management',
		name: 'calendarEventManagement',
		component: CalendarEventManagement,
		meta: { requiresAuth: true },
	},
	{
		path: '/live-event-management',
		name: 'liveEventManagement',
		component: LiveEventManagement,
		meta: { requiresAuth: true },
	},
	{
		path: '/services-management',
		name: 'servicesManagement',
		component: ServicesManagement,
		meta: { requiresAuth: true },
	},
	{
		path: '/users-visit',
		name: 'userVisitManagement',
		component: UserVisitManagement,
		meta: { requiresAuth: true },
	},
	{
		path: '/users-received',
		name: 'userReceivedManagement',
		component: UserReceivedManagement,
		meta: { requiresAuth: true },
	},
	{
		path: '/users-petition',
		name: 'userPetitionManagement',
		component: UserPetitionManagement,
		meta: { requiresAuth: true },
	},
	{
		path: '/users-group-life',
		name: 'userGroupLifeManagement',
		component: UserGroupLifeManagement,
		meta: { requiresAuth: true },
	},
	{
		path: '/group-life-management',
		name: 'groupLifeManagement',
		component: GroupLifeManagement,
		meta: { requiresAuth: true },
	},
	{
		path: '/donation-paypal-management',
		name: 'cardDonationPaypalManagement',
		component: CardDonationPaypalManagement,
		meta: { requiresAuth: true },
	},
	{
		path: '/donation-qr-management',
		name: 'cardDonationQRManagement',
		component: CardDonationQRManagement,
		meta: { requiresAuth: true },
	},
	{
		path: '/donation-way-management',
		name: 'cardDonationWayManagement',
		component: CardDonationWayManagement,
		meta: { requiresAuth: true },
	},
	{
		path: '*',
		name: 'home',
		redirect: '/',
	},
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
});

router.beforeEach((to, from, next) => {
	const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

	if (requiresAuth && !store.state.isLoggedIn) {
		next('/login');
	} else {
		next();
	}
});

export default router;
