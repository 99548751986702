import ApiServicesAdmin from '@/services/ApiServicesAdmin';
import alerts from '@/utilities/alerts';
export default {
	name: 'CalendarEventManagement',
	data() {
		return {
			title: 'Gestión Calendario de Eventos',
			e6: 1,
			activePicker: null,
			dateStartModel: null,
			menuStartDate: false,
			timeStart: null,
			menuStartTimeModel: false,
			menuEndDate: false,
			dateEndModel: null,
			timeEnd: null,
			menuEndTimeModel: false,
			minEndTime: '00:00',
			events: [],
			dialog: false,
			dialogDelete: false,
			headers: [
				{ text: 'Título Ingles', value: 'name.english' },
				{ text: 'Título Español', value: 'name.spanish' },
				{ text: 'Fecha Inicio', value: 'start' },
				{ text: 'Fecha Fin', value: 'end' },
				{ text: 'Acciones', value: 'actions', sortable: false },
			],
			colors: ['success', 'primary', 'secondary'],
			editedIndex: -1,
			editedItem: {
				_id: '',
				name: [
					{
						english: '',
						spanish: '',
					},
				],
				start: '',
				end: '',
				color: '',
			},
			defaultItem: {
				_id: '',
				name: [
					{
						english: '',
						spanish: '',
					},
				],
				start: '',
				end: '',
				color: '',
			},
			regexTitles: {
				spanish: /^[A-Za-zñÑáéíóúÁÉÍÓÚ\s]{5,25}$/,
				english: /^[A-Za-z\s]{5,25}$/,
			},
		};
	},
	methods: {
		async getEvents() {
			try {
				const data = await ApiServicesAdmin.getEvents();
				this.events = data;
			} catch (error) {
				console.log('Error get events to calendar ' + error);
			}
		},
		async save() {
			try {
				var randomIndex = Math.floor(Math.random() * this.colors.length);
				const values = {
					name: {
						spanish: this.editedItem.name.spanish,
						english: this.editedItem.name.english,
					},
					start: `${this.dateStartModel} ${this.timeStart}`,
					end: `${this.dateEndModel} ${this.timeEnd}`,
					color: this.editedItem.color || this.colors[randomIndex],
				};

				const result =
					this.editedIndex > -1
						? await ApiServicesAdmin.updateEventToCalendar({
								...values,
								_id: this.editedItem._id,
						  })
						: await ApiServicesAdmin.addEventToCalendar(values);

				const message = this.editedIndex > -1 ? result.result : result.result;
				alerts[`showAlert${this.editedIndex > -1 ? 'Update' : 'Add'}`](message);
				this.close();
			} catch (error) {
				console.error(`Error saving new event to calendar: ${error}`);
			}
		},
		editItem(item) {
			const dateStart = item.start.split(' ');
			const dateEnd = item.end.split(' ');

			this.dateStartModel = dateStart[0];
			this.timeStart = dateStart[1];
			this.dateEndModel = dateEnd[0];
			this.timeEnd = dateEnd[1];

			const values = JSON.parse(JSON.stringify(item));
			this.editedIndex = this.events.indexOf(item);
			this.editedItem = Object.assign({}, values);
			this.dialog = true;
		},
		close() {
			this.dialog = false;
			this.dateStartModel = null;
			this.dateEndModel = null;
			this.timeStart = null;
			this.timeEnd = null;
			this.e6 = 1;
			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem);
				this.editedIndex = -1;
			});
		},
		closeDelete() {
			this.dialogDelete = false;
			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem);
				this.editedIndex = -1;
			});
		},
		deleteItem(item) {
			const values = JSON.parse(JSON.stringify(item));
			this.editedIndex = this.events.indexOf(item);
			this.editedItem = Object.assign({}, values);
			this.dialogDelete = true;
		},
		async deleteItemConfirm() {
			const value = JSON.parse(JSON.stringify(this.editedItem));
			const result = await ApiServicesAdmin.deleteEventToCalendar(value._id);
			alerts.showAlertDelete(result.result);
			this.closeDelete();
		},
		saveStartDate(date) {
			this.$refs.menuStartDate.save(date);
		},
		saveEndDate(date) {
			this.$refs.menuEndDate.save(date);
			if (date === this.dateStartModel) {
				this.minEndTime = this.timeStart;
			} else {
				this.minEndTime = '00:00';
			}
		},
		getStartDateAndTime() {
			this.e6 = 3;
			if (this.editedItem._id === '') {
				this.dateEndModel = null;
				this.timeEnd = null;
			}
		},
		activeButton() {
			if (this.validateTitleSpanish && this.validateTitleEnglish) {
				return false;
			}
			return true;
		},
	},
	computed: {
		formTitle() {
			return this.editedIndex === -1 ? 'Agregar Evento' : 'Editar Evento';
		},
		validateTitleSpanish() {
			if (typeof this.editedItem.name.spanish === 'undefined') {
				return false;
			} else {
				return this.regexTitles.spanish.test(this.editedItem.name.spanish);
			}
		},
		validateTitleEnglish() {
			if (typeof this.editedItem.name.english === 'undefined') {
				return false;
			} else {
				return this.regexTitles.english.test(this.editedItem.name.english);
			}
		},
		activateStartTime() {
			return this.dateStartModel === null ? true : false;
		},
		activeButtonNext() {
			return this.timeStart === null ? true : false;
		},
		activateEndTime() {
			return this.dateEndModel === null ? true : false;
		},
		activeButtonSave() {
			return this.timeEnd === null ? true : false;
		},
	},
	watch: {
		events(newVal, oldVal) {
			this.getEvents();
		},
		dialog(val) {
			val || this.close();
		},
		dialogDelete(val) {
			val || this.closeDelete();
		},
		menuStartDate(val) {
			val && setTimeout(() => (this.activePicker = 'YEAR'));
		},
		menuEndDate(val) {
			val && setTimeout(() => (this.activePicker = 'YEAR'));
		},
	},
	mounted() {
		this.getEvents();
	},
	created() {
		this.getEvents();
	},
};
