import ApiServices from '@/services/ApiServices';

import { mapState } from 'vuex';

export default {
	name: 'Footer',
	data() {
		return {
			values: [],
			socialNetworks: [],
			typeUser: 'admin',
		};
	},
	methods: {
		async getDataFooter() {
			try {
				const data = await ApiServices.getDataFooter(this.$store.state.language);
				this.values = data[0];
			} catch (error) {
				console.log('Error get data footer ' + error);
			}
		},
		async getSocialNetworks() {
			try {
				const data = await ApiServices.getSocialNetworks();
				this.socialNetworks = data;
			} catch (error) {
				console.log('Error get social networks ' + error);
			}
		},
		changeTypeUser() {
			const { typeUser } = this;
			this.$store.commit('setTypeUser', typeUser);
		},
	},
	computed: {
		...mapState({
			language: (state) => state.language,
		}),
	},
	watch: {
		language: {
			handler() {
				this.getDataFooter();
			},
		},
		socialNetworks(newVal, oldVal) {
			this.getSocialNetworks();
		},
	},
	mounted() {
		this.getDataFooter();
		this.getSocialNetworks();
	},
};
