export default {
	name: 'CardWayDonation',
	props: {
		_id: String,
		title: String,
		description: String,
		data: {
			type: Object,
			default: () => ({
				_id: '',
				title: {
					spanish: '',
					english: '',
				},
				description: {
					spanish: '',
					english: '',
				},
			}),
		},
	},
	methods: {
		editCard() {
			this.$emit('edit', this.data);
		},
	},
};
