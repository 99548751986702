import '/node_modules/flag-icons/css/flag-icons.min.css';
import ApiServices from '@/services/ApiServices';
export default {
	name: 'ToolBar',
	data() {
		return {
			Title: 'Iglesia Camino Nuevo',
			Options: [],
			language: 'spanish',
			tabs: null,
			drawer: false,
			generalInformation: [],
		};
	},
	methods: {
		async getMenuOptions() {
			try {
				const data = await ApiServices.getMenuOptions(this.$store.state.language);
				data.sort((a, b) => a.order - b.order);
				this.Options = data;
			} catch (error) {
				console.log('Error getting menu options: ' + error);
			}
		},

		changeLanguage() {
			const { language } = this;
			this.$store.commit('setLanguage', language);
		},
		async getGeneralInformation() {
			try {
				const result = await ApiServices.getDataFooter();
				this.generalInformation = result[0];
			} catch (error) {
				console.log('Error get general information ' + error);
			}
		},
	},
	watch: {
		language: {
			handler() {
				this.changeLanguage();
				this.getMenuOptions();
			},
		},
		generalInformation(newVal, oldVal) {
			this.getGeneralInformation();
		},
	},
	mounted() {
		this.getMenuOptions();
		this.getGeneralInformation();
	},
};
