import ApiServices from '@/services/ApiServices';
import ApiServicesAdmin from '@/services/ApiServicesAdmin';
import alerts from '@/utilities/alerts';
const Swal = require('sweetalert2');

export default {
	name: 'SocialNetworkManagement',
	data() {
		return {
			title: 'Gestión Redes Sociales',
			dialog: false,
			dialogDelete: false,
			socialNetworks: [],
			headers: [
				{ text: 'Nombre', value: 'name' },
				{ text: 'Enlace', value: 'address' },
				{ text: 'Acciones', value: 'actions', sortable: false },
			],
			editedIndex: -1,
			editedItem: {
				_id: '',
				name: '',
				address: '',
				icon: '',
			},
			defaultItem: {
				_id: '',
				name: '',
				address: '',
				icon: '',
			},
			select: { name: '', icon: '' },
			listSocialNetworks: [
				{ name: 'Facebook', icon: 'mdi-facebook' },
				{ name: 'Instagram', icon: 'mdi-instagram' },
				{ name: 'YouTube', icon: 'mdi-youtube' },
				{ name: 'Twitter', icon: 'mdi-twitter' },
				{ name: 'LinkedIn', icon: 'mdi-linkedin' },
			],
			items: [],
			edit: true,
			inputSelect: true,
			regexURL: /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/,
		};
	},
	methods: {
		async getSocialNetworks() {
			try {
				const data = await ApiServices.getSocialNetworks();
				this.socialNetworks = data;
				const namesSocialNetwork = data.map((excluded) => excluded.name);
				this.items = this.listSocialNetworks.filter(
					(item) => !namesSocialNetwork.includes(item.name)
				);
			} catch (error) {
				console.log('Error get social network ' + error);
			}
		},

		async save() {
			try {
				const values = JSON.parse(JSON.stringify(this.editedItem));
				if (values._id === '') {
					const socialNetwork = JSON.parse(JSON.stringify(this.select));
					values.name = socialNetwork.name;
					values.icon = socialNetwork.icon;
					const result = await ApiServicesAdmin.addSocialNetwork(values);
					alerts.showAlertAdd(result.result);
					this.close();
				} else {
					const result = await ApiServicesAdmin.updateSocialNetwork(values);
					alerts.showAlertUpdate(result.result);
					this.close();
				}
			} catch (error) {
				console.log('Error save new mission ' + error);
			}
		},
		editItem(item) {
			const values = JSON.parse(JSON.stringify(item));
			this.editedIndex = this.socialNetworks.indexOf(item);
			this.editedItem = Object.assign({}, values);
			this.dialog = true;
			this.edit = false;
			this.inputSelect = false;
		},
		close() {
			this.dialog = false;
			this.inputSelect = true;
			this.edit = true;
			this.select = { name: '', icon: '' };
			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem);
				this.editedIndex = -1;
			});
		},
		closeDelete() {
			this.dialogDelete = false;
			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem);
				this.editedIndex = -1;
			});
		},
		deleteItem(item) {
			const values = JSON.parse(JSON.stringify(item));
			this.editedIndex = this.socialNetworks.indexOf(item);
			this.editedItem = Object.assign({}, values);
			this.dialogDelete = true;
		},
		async deleteItemConfirm() {
			const value = JSON.parse(JSON.stringify(this.editedItem));
			const result = await ApiServicesAdmin.deleteSocialNetwork(value._id);
			result.result
				? alerts.showAlertDelete(result.result)
				: this.showAlertDelete(result.result);
			this.closeDelete();
		},
	},
	computed: {
		formTitle() {
			return this.editedIndex === -1 ? 'Agregar Red Social' : 'Editar Red Social';
		},
		validateURL() {
			return this.regexURL.test(this.editedItem.address);
		},
	},
	watch: {
		socialNetworks(newVal, oldVal) {
			this.getSocialNetworks();
		},
		dialog(val) {
			val || this.close();
		},
		dialogDelete(val) {
			val || this.closeDelete();
		},
		select(newVal, oldVal) {
			const social = JSON.parse(JSON.stringify(newVal));
			if (social.name != '') {
				this.inputSelect = false;
			}
		},
	},
	mounted() {
		this.getSocialNetworks();
	},
	created() {
		this.getSocialNetworks();
	},
};
