import { mapState } from 'vuex';
export default {
	name: 'CardGroupLife',
	props: {
		_id: String,
		photo: String,
		name: String,
		details: String,
		typeModality: Number,
		modality: String,
		address: String,
		days: Array,
		hour: String,
		inCharge: String,
		contact: String,
		data: {
			type: Object,
			default: () => ({
				_id: '',
				photo: '',
				name: {
					spanish: '',
					english: '',
				},
				details: {
					spanish: '',
					english: '',
				},
				typeModality: '',
				modality: '',
				address: '',
				days: {},
				hour: '',
				inCharge: '',
				contact: '',
			}),
		},
	},
	data() {
		return {
			show: false,
			labels: {
				spanish: {
					modality: 'Modalidad',
					address: 'Ubicación',
					url: 'Enlace',
					hour: 'Hora',
				},
				english: {
					modality: 'Modality',
					address: 'Ubication',
					url: 'Link',
					hour: 'Hour',
				},
			},
			labelSelecteds: [],
		};
	},
	methods: {
		openModal() {
			this.$emit('openModal', this.modalId);
		},
		editCard() {
			this.$emit('edit', this.data);
		},
		deleteCard() {
			this.$emit('remove', this.data._id);
		},
		selectLabelsByLanguage() {
			try {
				const language = this.$store.state.language;
				this.labelSelecteds = this.labels[language];
			} catch (error) {
				console.log('Error select labels by language ' + error);
			}
		},
	},
	computed: {
		...mapState({
			language: (state) => state.language,
		}),
	},
	watch: {
		language: {
			handler() {
				this.selectLabelsByLanguage();
			},
		},
	},
	mounted() {
		this.selectLabelsByLanguage();
	},
};
