import CardGroupLife from '../CardGroupLife/CardGroupLife.vue';
import ApiServices from '@/services/ApiServices';
import { mapState } from 'vuex';

export default {
	name: 'SectionGroupLife',
	data() {
		return {
			groupsLife: [],
		};
	},
	components: {
		CardGroupLife,
	},
	methods: {
		async getGroupLife() {
			try {
				const data = await ApiServices.getGroupsLife(this.$store.state.language);
				this.groupsLife = data;
			} catch (error) {
				console.log('Error get groups life ' + error);
			}
		},
	},
	computed: {
		...mapState({
			language: (state) => state.language,
		}),
	},
	watch: {
		language: {
			handler() {
				this.getGroupLife();
			},
		},
	},
	mounted() {
		this.getGroupLife();
	},
};
