import ApiServices from '@/services/ApiServices';
import ApiServicesAdmin from '@/services/ApiServicesAdmin';

import { mapState } from 'vuex';
export default {
	name: 'SectionMultimedia',
	data() {
		return {
			images: [],
			songs: [],
			titlesSections: {
				spanish: {
					images: 'UN VISTAZO A LO QUE HACEMOS',
					preaching: 'NUESTRAS PREDICACIONES',
				},
				english: {
					images: 'A GLIMPSE OF WHAT WE DO',
					preaching: 'OUR PREACHINGS',
				},
			},
			titlesSelecteds: [],
		};
	},
	methods: {
		async getImages() {
			try {
				const data = await ApiServicesAdmin.getImagesHome();
				this.images = data;
			} catch (error) {
				console.log('Error get images ' + error);
			}
		},
		async getSongs() {
			try {
				const data = await ApiServicesAdmin.getSongs();
				this.songs = data;
			} catch (error) {
				console.log('Error get songs ' + error);
			}
		},
		selectTitlesByLanguage() {
			try {
				const language = this.$store.state.language;
				this.titlesSelecteds = this.titlesSections[language];
			} catch (error) {
				console.log('Error select title by language ' + error);
			}
		},
	},
	computed: {
		...mapState({
			language: (state) => state.language,
		}),
	},
	watch: {
		language: {
			handler() {
				this.selectTitlesByLanguage();
			},
		},
		images(newVal, oldVal) {
			this.getImages();
		},
		songs(newVal, oldVal) {
			this.getSongs();
		},
	},
	mounted() {
		this.getImages();
		this.getSongs();
		this.selectTitlesByLanguage();
	},
};
