import CardRegister from '..//CardRegister/CardRegister.vue';
import FormRegister from '../FormRegister/FormRegister.vue';
import ApiServices from '@/services/ApiServices';
import { mapState } from 'vuex';

export default {
	name: 'SectionRegister',
	components: {
		CardRegister,
		FormRegister,
	},

	data() {
		return {
			titles: {
				spanish: '¿QUIERES SER PARTE DE NOSOTROS?',
				english: 'DO YOU WANT TO BE PART OF US?',
			},
			title: '',
			registrationCards: [],
			modalId: 0,
			show: false,
		};
	},
	methods: {
		async getCardRegister() {
			try {
				const data = await ApiServices.getCardRegister(this.$store.state.language);
				this.registrationCards = data;
			} catch (error) {
				console.log('Error get cards register information ' + error);
			}
		},
		selectTitleByLanguage() {
			try {
				const language = this.$store.state.language;
				this.title = this.titles[language];
			} catch (error) {
				console.log('Error select title ' + error);
			}
		},
		handleModal(data) {
			this.modalId = data;
			this.show = true;
		},
		handleModalClose(data) {
			this.show = data;
		},
	},
	computed: {
		...mapState({
			language: (state) => state.language,
		}),
	},
	watch: {
		language: {
			handler() {
				this.getCardRegister();
				this.selectTitleByLanguage();
			},
		},
	},
	mounted() {
		this.getCardRegister();
		this.selectTitleByLanguage();
	},
};
