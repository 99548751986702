import ApiServicesAdmin from '@/services/ApiServicesAdmin';
import alerts from '@/utilities/alerts';

export default {
	name: 'UserVisitManagement',
	data() {
		return {
			title: 'Nuevos usuarios en la aplicación',
			users: [],
			idUserChange: '',
			search: '',
			dialogChangeUserStatus: false,
			headers: [
				{ text: 'Apellidos/Nombres', value: 'fullName', align: 'center' },
				{ text: 'Edad', value: 'age', align: 'center' },
				{ text: 'Género', value: 'gender', align: 'center' },
				{ text: 'Teléfono', value: 'phone', align: 'center' },
				{ text: 'Email', value: 'email', align: 'center' },
				{ text: 'Fecha registro', value: 'registrationDate', align: 'center' },
				{ text: 'Nuevo Usuario', value: 'newUser', align: 'center' },
				{ text: 'Contactado', value: 'actions', sortable: false, align: 'center' },
			],
		};
	},
	methods: {
		async getUsers() {
			try {
				const data = await ApiServicesAdmin.getUsersVisit();
				this.users = data;
			} catch (error) {
				console.log('Error get user visit ' + error);
			}
		},
		closeChangeUserStatus() {
			this.dialogChangeUserStatus = false;
		},
		changeUserStatus(item) {
			this.idUserChange = item;
			this.dialogChangeUserStatus = true;
		},
		async changeUserConfirm() {
			const result = await ApiServicesAdmin.updateUser(this.idUserChange);
			alerts.showAlertUpdate(result.result);
			this.closeChangeUserStatus();
		},
	},
	computed: {},
	watch: {
		users() {
			this.getUsers();
		},
	},
	mounted() {
		this.getUsers();
	},
	created() {
		this.getUsers();
	},
};
