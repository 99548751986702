<template>
	<SectionEvents />
</template>
<script>
	import SectionEvents from '@/components/user/SectionEvents/SectionEvents.vue';
	export default {
		name: 'EventUserView',
		components: {
			SectionEvents,
		},
		data() {
			return {
				title: 'Eventos',
			};
		},
	};
</script>
