import { mapState } from 'vuex';
export default {
	name: 'CardService',
	props: {
		_id: String,
		title: String,
		day: String,
		hour: String,
		description: String,
		inCharge: String,
		data: {
			type: Object,
			default: () => ({
				_id: '',
				title: {
					spanish: '',
					english: '',
				},
				day: {
					spanish: '',
					english: '',
				},
				hour: '',
				description: {
					spanish: '',
					english: '',
				},
				inCharge: '',
			}),
		},
	},
	data() {
		return {
			labels: {
				spanish: {
					day: 'Día',
					hour: 'Hora',
					inCharge: 'Encargado',
					detail: 'Detalles',
				},
				english: {
					day: 'Day',
					hour: 'Hour',
					inCharge: 'In Charge',
					detail: 'Details',
				},
			},
			selectedLabels: {},
		};
	},
	methods: {
		selectLabel() {
			try {
				const language = this.$store.state.language;
				this.selectedLabels = this.labels[language];
			} catch (error) {
				console.log('Error select labels ' + error);
			}
		},
		editCard() {
			this.$emit('edit', this.data);
		},
		deleteCard() {
			this.$emit('remove', this.data._id);
		},
	},
	computed: {
		...mapState({
			language: (state) => state.language,
		}),
	},
	watch: {
		language: {
			handler() {
				this.selectLabel();
			},
		},
	},
	mounted() {
		this.selectLabel();
	},
};
