import CardDonationQR from '@/components/user/DonationQR/DonationQR.vue';
import ApiServicesAdmin from '@/services/ApiServicesAdmin';
import alerts from '@/utilities/alerts';
export default {
	name: 'CardDonationQRManagement',
	components: {
		CardDonationQR,
	},
	data() {
		return {
			title: 'DONACIONES QR',
			dialog: false,
			e6: 1,
			information: [],
			data: {
				image: '',
				title: {
					spanish: '',
					english: '',
				},
				subtitle: {
					spanish: '',
					english: '',
				},
				address: '',
			},
			regexTitle: {
				spanish: /^[A-Za-zñÑáéíóúÁÉÍÓÚ\s]{5,30}$/,
				english: /^[A-Za-z\s]{5,30}$/,
			},
			regexSubtitle: {
				spanish: /^[A-Za-zñÑáéíóúÁÉÍÓÚ\s]{5,50}$/,
				english: /^[A-Za-z\s]{5,50}$/,
			},
		};
	},
	methods: {
		async getInformation() {
			try {
				const data = await ApiServicesAdmin.getDonationQR();
				this.information = data;
			} catch (error) {
				console.log('Error get data card donation qr admin ' + error);
			}
		},
		edit(data) {
			this.dialog = true;
			this.data = JSON.parse(JSON.stringify(data));
		},

		getPathImage(image) {
			this.data.image = image;
		},
		closeModal() {
			this.dialog = false;
			this.e6 = 1;
			this.data = {
				image: '',
				title: {
					spanish: '',
					english: '',
				},
				subtitle: {
					spanish: '',
					english: '',
				},
				address: '',
			};
		},
		async save() {
			try {
				const formData = new FormData();
				const { ...values } = { ...this.data };
				formData.append('_id', values._id);
				formData.append('image', values.image);
				formData.append('title', JSON.stringify(values.title));
				formData.append('subtitle', JSON.stringify(values.subtitle));
				formData.append('address', values.address);
				const result = await ApiServicesAdmin.updateCardDonationQR(formData);
				alerts.showAlertUpdate(result.result);
				this.closeModal();
			} catch (error) {
				console.log('Error update card donation qr ' + error);
				this.closeModal();
			}
		},
	},
	computed: {
		validateTitles() {
			var titleSpanish = this.regexTitle.spanish.test(this.data.title.spanish);
			var titleEnglish = this.regexTitle.english.test(this.data.title.english);
			if (titleSpanish && titleEnglish) {
				return false;
			}
			return true;
		},
		validateSubtitles() {
			var subtitleSpanish = this.regexSubtitle.spanish.test(this.data.subtitle.spanish);
			var subtitleEnglish = this.regexSubtitle.english.test(this.data.subtitle.english);
			if (subtitleSpanish && subtitleEnglish) {
				return false;
			}
			return true;
		},
	},
	watch: {
		information(newVal, oldVal) {
			this.getInformation();
		},
	},
	mounted() {
		this.getInformation();
	},
};
