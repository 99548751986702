import CardPastoral from '@/components/user/CardPastoralTeam/CardPastoralTeam.vue';
import ApiServices from '@/services/ApiServicesAdmin';
import alerts from '@/utilities/alerts';

export default {
	name: 'PastoralTeamManagement',
	components: {
		CardPastoral,
	},
	data() {
		return {
			title: 'Gestión Equipo Pastoral',
			dialogEdit: true,
			dialog: false,
			e6: 1,
			cards: [],
			page: 1,
			numCardsPerPage: 1,
			data: {
				photo: '',
				description: {
					spanish: '',
					english: '',
				},
				name: '',
				lastname: '',
				position: {
					spanish: '',
					english: '',
				},
				type: 2,
			},
			regexNameAndLastname: /^[A-Za-zñÑáéíóúÁÉÍÓÚ\s]{5,30}$/,
			regexPositions: {
				spanish: /^[A-Za-zñÑáéíóúÁÉÍÓÚ\s]{5,30}$/,
				english: /^[A-Za-z\s]{5,30}$/,
			},
			regexDescriptions: {
				spanish: /^[A-Za-zñÑáéíóúÁÉÍÓÚ0-9\s?!¡¿.,:;"/]{5,1000}$/,
				english: /^[A-Za-z0-9\s?!¡¿.,:;"/]{5,1000}$/,
			},
			validateButton: true,
		};
	},
	methods: {
		async getPastoralData() {
			try {
				const data = await ApiServices.getDataPastoralTeam();
				this.cards = data;
			} catch (error) {
				console.log('Error get data pastoral team ' + error);
			}
		},
		edit(data) {
			this.dialog = true;
			this.data = JSON.parse(JSON.stringify(data));
			this.dialogEdit = false;
			this.validateButton = false;
		},
		async remove(id) {
			try {
				const result = await ApiServices.deletePastoralUser(id);
				alerts.showAlertDelete(result.result);
			} catch (error) {
				console.log('Error delete user pastor ' + error);
			}
		},
		closeModal() {
			this.dialog = false;
			this.validateButton = true;
			this.e6 = 1;
			this.data = {
				photo: '',
				description: {
					spanish: '',
					english: '',
				},
				name: '',
				lastname: '',
				position: {
					spanish: '',
					english: '',
				},
				type: 2,
			};
		},

		async save() {
			try {
				const formData = new FormData();
				const { ...values } = { ...this.data };
				formData.append('_id', values._id);
				formData.append('description', JSON.stringify(values.description));
				formData.append('name', values.name);
				formData.append('lastname', values.lastname);
				formData.append('position', JSON.stringify(values.position));
				formData.append('type', values.type);
				formData.append('photo', values.photo);
				if (!values._id) {
					const result = await ApiServices.addPastoralUser(formData);
					alerts.showAlertAdd(result.result);
					this.closeModal();
				} else {
					const result = await ApiServices.updatePastoralUser(formData);
					alerts.showAlertUpdate(result.result);
					this.closeModal();
				}
			} catch (error) {
				console.log('Error ' + error);
			}
		},
		getPathImage(image) {
			if ((image === '' || image === null) && this.dialogEdit) {
				this.validateButton = true;
			} else {
				this.data.photo = image;
				this.validateButton = false;
			}
		},
	},
	computed: {
		numPages() {
			return Math.ceil(this.cards.length / this.numCardsPerPage);
		},
		visibleCards() {
			const start = (this.page - 1) * this.numCardsPerPage;
			const end = start + this.numCardsPerPage;
			return this.cards.slice(start, end);
		},
		validateNameAndLastname() {
			var name = this.regexNameAndLastname.test(this.data.name);
			var lastname = this.regexNameAndLastname.test(this.data.lastname);
			if (name && lastname) {
				return false;
			}
			return true;
		},
		validatePositions() {
			var positionSpanish = this.regexPositions.spanish.test(this.data.position.spanish);
			var positionEnglish = this.regexPositions.english.test(this.data.position.english);
			if (positionSpanish && positionEnglish) {
				return false;
			}
			return true;
		},

		validateDescriptions() {
			var descriptionSpanish = this.regexDescriptions.spanish.test(
				this.data.description.spanish
			);
			var descriptionEnglish = this.regexDescriptions.english.test(
				this.data.description.english
			);
			if (descriptionSpanish && descriptionEnglish) {
				return false;
			}
			return true;
		},
	},
	watch: {
		cards(newVal, oldVal) {
			this.getPastoralData();
		},
	},
	mounted() {
		this.getPastoralData();
	},
};
