export default {
	name: 'CardRegister',
	props: {
		_id: String,
		modalId: Number,
		imgSrc: String,
		title: String,
		subtitle: String,
		description: String,
		buttonText: String,
		data: {
			type: Object,
			default: () => ({
				_id: '',
				image: {
					spanish: '',
					english: '',
				},
				title: {
					spanish: '',
					english: '',
				},
				subtitle: {
					spanish: '',
					english: '',
				},
				description: {
					spanish: '',
					english: '',
				},
				button: {
					spanish: '',
					english: '',
				},
			}),
		},
	},
	methods: {
		openModal() {
			this.$emit('openModal', this.modalId);
		},
		editCard() {
			this.$emit('edit', this.data);
		},
		deleteCard() {
			this.$emit('remove', this.data._id);
		},
	},
};
