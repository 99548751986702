import { render, staticRenderFns } from "./SocialNetworkManagement.vue?vue&type=template&id=1c65adf8&"
import script from "./SocialNetworkManagement?vue&type=script&lang=js&"
export * from "./SocialNetworkManagement?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports