const onlyLettersAndSpaces = /^[a-zA-Z\s]+$/;
const onlyNumbers = /^\d+$/;
const validEmail = /@/;

function validateName(v) {
	return onlyLettersAndSpaces.test(v) || 'Only letters and spaces are allowed in names';
}

function validateGenre(v) {
	return !!v || 'Please select a value';
}

function validateDate(v) {
	const minDate = new Date('1930-01-01');
	const maxDate = new Date('2018-01-01');
	const selectedDate = new Date(v);
	return (
		(selectedDate >= minDate && selectedDate <= maxDate) ||
		'Please enter a valid date between 1900 and today'
	);
}

function validatePhone(v) {
	return onlyNumbers.test(v) || 'Please enter only numbers';
}

function validateEmail(v) {
	return validEmail.test(v) || 'Please enter a valid email';
}

function validatePetition(v) {
	return !!v || 'Please select a value';
}

function validateLength(len) {
	return (v) => (v || '').length >= len || `Invalid character length, required ${len}`;
}

function validateRequired(v) {
	return !!v || 'This field is required';
}

const rules = {
	name: validateName,
	lastname: validateName,
	genre: validateGenre,
	date: validateDate,
	phone: validatePhone,
	email: validateEmail,
	petition: validatePetition,
	length: validateLength,
	required: validateRequired,
};

export default rules;
