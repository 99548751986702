import CardDonation from '../CardDonationPaypal/CardDonationPaypal.vue';
import DonationQR from '../DonationQR/DonationQR.vue';
import CardWayDonation from '../CardWayDonation/CardWayDonation.vue';
import ApiServices from '@/services/ApiServices';
import { mapState } from 'vuex';
import alerts from '@/utilities/alerts';

export default {
	name: 'SectionDonation',
	data() {
		return {
			cardsDonation: [],
			donationQR: [],
			waysDonation: [],
			urlDonation: '',
			msgAlerts: {
				spanish: {
					msgAmountEmpty: 'Seleccione Una Cantidad A Donar',
					msgAmountIncorrect: 'Cantidad Ingresada Incorrecta',
					msgRedirectDonation:
						'❤️¡Prepárate Para Donar!❤️ <br/> Serás Dirigido A La Aplicación De Donaciones',
				},
				english: {
					msgAmountEmpty: 'Select An Amount To Donate',
					msgAmountIncorrect: 'Incorrect Amount Entered',
					msgRedirectDonation:
						'❤️Get Ready To Donate!❤️ <br/> You Will Be Directed To The Donate App',
				},
			},
			selectedMsgAlerts: {},
			showDonationPaypal: true,
		};
	},
	components: {
		CardDonation,
		DonationQR,
		CardWayDonation,
	},
	methods: {
		async getInformationDonationQR() {
			try {
				const data = await ApiServices.getDonationQR(this.$store.state.language);
				this.donationQR = data;
			} catch (error) {
				console.log('Error get information donation qr ' + error);
			}
		},
		async getDataCardDonation() {
			try {
				const data = await ApiServices.getInformationCardDonation(
					this.$store.state.language
				);
				this.cardsDonation = data;
				this.showDonationPaypal = this.cardsDonation.show;
			} catch (error) {
				console.log('Error get data card donation ' + error);
			}
		},
		async getWayDonation() {
			try {
				const data = await ApiServices.getWayDonation(this.$store.state.language);
				this.waysDonation = data;
			} catch (error) {
				console.log('Error get information way donation ' + error);
			}
		},
		async confirmDonation(amount) {
			if (amount != '') {
				if (amount <= 0) {
					alerts.alertAmountIncorrect(this.selectedMsgAlerts.msgAmountIncorrect);
				} else {
					try {
						const data = await ApiServices.getPageDonation(amount);
						alerts.alertRedirectDonation(
							data,
							this.selectedMsgAlerts.msgRedirectDonation
						);
					} catch (error) {
						console.log('Error get url donation ' + error);
					}
				}
			} else {
				alerts.alertAmountEmpty(this.selectedMsgAlerts.msgAmountEmpty);
			}
		},
		closeModal() {
			this.dialog = false;
			this.urlDonation = '';
		},
		selectMsgAlertsByLanguage() {
			try {
				const language = this.$store.state.language;
				this.selectedMsgAlerts = this.msgAlerts[language];
			} catch (error) {
				console.log('Error select titles alert by language ' + error);
			}
		},
	},
	computed: {
		...mapState({
			language: (state) => state.language,
		}),
	},
	watch: {
		language: {
			handler() {
				this.getInformationDonationQR();
				this.getDataCardDonation();
				this.getWayDonation();
				this.selectMsgAlertsByLanguage();
			},
		},
	},
	mounted() {
		this.getInformationDonationQR();
		this.getDataCardDonation();
		this.getWayDonation();
		this.selectMsgAlertsByLanguage();
	},
};
