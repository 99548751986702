<template>
	<div class="rounded-xl">
		<v-row no-gutters>
			<v-col cols="12" sm="12" md="12">
				<video
					class="rounded-lg"
					:src="this.video"
					autoplay
					muted
					loop
					width="100%"></video>
			</v-col>
		</v-row>
	</div>
</template>
<script>
	const Swal = require('sweetalert2');
	import ApiServices from '@/services/ApiServices';

	export default {
		name: 'Presentation',
		components: {},
		data() {
			return {
				video: '',
			};
		},
		methods: {
			async getVideoPresentation() {
				try {
					const data = await ApiServices.getVideoPresentation();
					this.video = data.video;
				} catch (error) {
					console.log('Error get video presentation ' + error);
				}
			},
		},
		watch: {
			video(newVal, oldVal) {
				this.getVideoPresentation();
			},
		},
		mounted() {
			this.getVideoPresentation();
		},
	};
</script>
