import CardService from '@/components/user/CardService/CardService.vue';
import ApiServicesAdmin from '@/services/ApiServicesAdmin';
import alerts from '@/utilities/alerts';
export default {
	name: 'ServicesManagement',
	components: {
		CardService,
	},
	data() {
		return {
			title: 'Gestión de Servicios',
			dialogEdit: true,
			dialog: false,
			e6: 1,
			services: [],
			page: 1,
			numCardsPerPage: 4,
			data: {
				title: {
					spanish: '',
					english: '',
				},
				day: {
					spanish: '',
					english: '',
				},
				hour: '',
				description: {
					spanish: '',
					english: '',
				},
				inCharge: '',
			},
			select: { daySpanish: '', dayEnglish: '' },
			listOfDaysOfTheWeek: [
				{ daySpanish: 'Lunes', dayEnglish: 'Monday' },
				{ daySpanish: 'Martes', dayEnglish: 'Tuesday' },
				{ daySpanish: 'Miércoles', dayEnglish: 'Wednesday' },
				{ daySpanish: 'Jueves', dayEnglish: 'Thursday' },
				{ daySpanish: 'Viernes', dayEnglish: 'Friday' },
				{ daySpanish: 'Sábado', dayEnglish: 'Saturday' },
				{ daySpanish: 'Domingo', dayEnglish: 'Sunday' },
			],
			menuStartTimeModel: false,
			timeStart: null,
			validateButton: true,
			regexTitles: {
				spanish: /^[A-Za-zñÑáéíóúÁÉÍÓÚ\s]{5,30}$/,
				english: /^[A-Za-z\s]{5,30}$/,
			},
			inputSelect: true,
			regexInCharge: /^[A-Za-zñÑáéíóúÁÉÍÓÚ\s]{5,30}$/,
			regexDescriptions: {
				spanish: /^[A-Za-zñÑáéíóúÁÉÍÓÚ0-9\s?!¡¿.,:;"/]{5,500}$/,
				english: /^[A-Za-z0-9\s?!¡¿.,:;"/]{5,500}$/,
			},
		};
	},
	methods: {
		async getServices() {
			try {
				const data = await ApiServicesAdmin.getServices();
				this.services = data;
			} catch (error) {
				console.log('Error get services ' + error);
			}
		},
		async save() {
			try {
				const values = {
					_id: this.data._id,
					title: {
						spanish: this.data.title.spanish,
						english: this.data.title.english,
					},
					day: {
						spanish: this.select.daySpanish,
						english: this.select.dayEnglish,
					},
					hour: this.timeStart,
					description: {
						spanish: this.data.description.spanish,
						english: this.data.description.english,
					},
					inCharge: this.data.inCharge,
				};

				const result = !values._id
					? await ApiServicesAdmin.addService(values)
					: await ApiServicesAdmin.updateService(values);

				if (!values._id) {
					alerts.showAlertAdd(result.result);
				} else {
					alerts.showAlertUpdate(result.result);
				}

				this.closeModal();
			} catch (error) {
				console.error(`Error saving new service : ${error}`);
			}
		},
		edit(data) {
			this.dialog = true;
			this.select = { daySpanish: data.day.spanish, dayEnglish: data.day.english };
			this.data = JSON.parse(JSON.stringify(data));
			this.dialogEdit = false;
			this.validateButton = false;
		},
		async remove(id) {
			try {
				const result = await ApiServicesAdmin.deleteService(id);
				alerts.showAlertDelete(result.result);
			} catch (error) {
				console.log('Error delete service ' + error);
			}
		},
		closeModal() {
			this.dialog = false;
			this.validateButton = true;
			this.e6 = 1;
			this.data = {
				title: {
					spanish: '',
					english: '',
				},
				day: {
					spanish: '',
					english: '',
				},
				hour: '',
				description: {
					spanish: '',
					english: '',
				},
				inCharge: '',
			};
			this.timeStart = null;
			this.select = { daySpanish: '', dayEnglish: '' };
		},
	},
	computed: {
		numPages() {
			return Math.ceil(this.services.length / this.numCardsPerPage);
		},
		visibleCards() {
			const start = (this.page - 1) * this.numCardsPerPage;
			const end = start + this.numCardsPerPage;
			return this.services.slice(start, end);
		},
		validateTitles() {
			var titleSpanish = this.regexTitles.spanish.test(this.data.title.spanish);
			var titleEnglish = this.regexTitles.english.test(this.data.title.english);
			if (titleSpanish && titleEnglish) {
				return false;
			}
			return true;
		},
		activateStartTime() {
			return this.select.daySpanish === '' ? true : false;
		},
		activeButtonNext() {
			return this.timeStart === null ? true : false;
		},
		validateInCharge() {
			var inCharge = this.regexInCharge.test(this.data.inCharge);
			if (inCharge) {
				return false;
			}
			return true;
		},
		validateDescriptions() {
			var descriptionSpanish = this.regexDescriptions.spanish.test(
				this.data.description.spanish
			);
			var descriptionEnglish = this.regexDescriptions.english.test(
				this.data.description.english
			);
			if (descriptionSpanish && descriptionEnglish) {
				return false;
			}
			return true;
		},
	},
	watch: {
		services(newVal, oldVal) {
			this.getServices();
		},
	},
	mounted() {
		this.getServices();
	},
};
