<template>
	<v-card class="mx-auto mt-4 mb-4 rounded-lg" max-width="700">
		<v-row>
			<v-col cols="12" lg="6" sm="6" xs="12">
				<v-card-subtitle class="primary--text text-h6 text-center">
					IGLESIA CAMINO NUEVO
					<v-img :src="logoLogin" class="rounded-xl mt-2 ml-4"></v-img>
				</v-card-subtitle>
			</v-col>
			<v-divider vertical></v-divider>
			<v-col cols="12" lg="6" sm="6" xs="12">
				<v-card-subtitle class="primary--text text-h6 text-center"> LOGIN </v-card-subtitle>
				<v-divider></v-divider>
				<v-row class="mt-2">
					<v-card-text
						><v-form ref="form">
							<v-text-field
								label="Correo electrónico"
								type="email"
								v-model="email"
								:rules="[required('Correo electrónico')]"
								outlined></v-text-field>
							<v-text-field
								v-model="password"
								:append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
								label="Contraseña"
								:type="show1 ? 'text' : 'password'"
								:rules="[required('Contraseña')]"
								@click:append="show1 = !show1"
								outlined></v-text-field>
						</v-form>
						<v-btn
							color="primary"
							style="width: 100%"
							class="rounded-lg mt-2"
							@click="submit"
							>Iniciar sesión</v-btn
						>
						<v-btn color="secondary" class="rounded-lg mt-2" style="width: 100%" to="/"
							>Regresar</v-btn
						>
					</v-card-text>
				</v-row>
			</v-col>
		</v-row>
	</v-card>
</template>

<script>
	import { mapActions } from 'vuex';
	import { ref } from 'vue';
	const Swal = require('sweetalert2');
	export default {
		data() {
			return {
				email: '',
				password: '',
				emailLabel: '',
				passwordLabel: '',
				logoLogin: ref(require('@/assets/logoLogin.png')).value,
				show1: false,
			};
		},
		methods: {
			...mapActions(['logIn']),
			required(field) {
				return (value) => !!value || `${field} es requerido`;
			},
			async submit() {
				if (this.$refs.form.validate()) {
					try {
						await this.logIn({
							email: this.email,
							password: this.password,
						});
						this.$store.typeUser = 'admin';
						this.$router.replace('/admin');
					} catch (error) {
						Swal.fire({
							icon: 'error',
							title: 'Credenciales incorrectas',
							showConfirmButton: true,
						});
					}
				}
			},
			validateInput() {
				if (this.email === '') this.emailLabel = 'Correo electrónico';
				if (this.password === '') this.passwordLabel = 'Contraseña';
			},
		},
		watch: {
			email(newVal, oldVal) {
				this.validateInput();
			},
			password(newVal, oldVal) {
				this.validateInput();
			},
		},
	};
</script>
