import ApiServicesAdmin from '@/services/ApiServicesAdmin';
import { mapActions } from 'vuex';
export default {
	name: 'MenuBar',
	data() {
		return {
			typeUser: 'user',
			administrator: [],
			items: [
				{
					action: 'mdi-home',
					items: [
						{ title: 'Video Presentación', route: '/video-presentation-management' },
						{ title: 'Tarjetas Registro', route: '/card-register-management' },
						{ title: 'Imagenes', route: '/images-home-management' },
						{ title: 'Predicaciones', route: '/songs-management' },
					],
					title: 'Inicio',
				},
				{
					action: 'mdi-clipboard-account',
					items: [
						{ title: 'Misión', route: '/mission' },
						{ title: 'Valores Centrales', route: '/core-values' },
						{ title: 'Biografía Pastor', route: '/senior-pastoral-management' },
						{ title: 'Equipo Pastoral', route: '/pastoral-team-management' },
					],
					title: 'Nosotros',
				},
				{
					action: 'mdi-book-variant',
					items: [{ title: 'Gestión', route: '/services-management' }],
					title: 'Servicios',
				},
				{
					action: 'mdi-calendar',
					items: [
						{ title: 'Eventos importantes', route: '/important-events-management' },
						{ title: 'Calendario eventos', route: '/calendar-events-management' },
						{ title: 'Eventos en vivo', route: '/live-event-management' },
					],
					title: 'Eventos',
				},
				{
					action: 'mdi-account-multiple',
					items: [{ title: 'Gestión', route: '/group-life-management' }],
					title: 'Grupos de Vida',
				},
				{
					action: 'mdi-heart',
					items: [
						{ title: 'PayPal', route: '/donation-paypal-management' },
						{ title: 'QR', route: '/donation-qr-management' },
						{ title: 'Otros', route: '/donation-way-management' },
					],
					title: 'Donaciones',
				},
				{
					action: 'mdi-account-multiple',
					items: [
						{
							title: 'Nuevos usuarios',
							route: '/users-visit',
						},
						{ title: 'Cristo como su Salvador', route: '/users-received' },
						{ title: 'Peticiones', route: '/users-petition' },
						{ title: 'Grupos de vida', route: '/users-group-life' },
					],
					title: 'Usuarios',
				},
				{
					action: 'mdi-dots-vertical-circle',
					items: [{ title: 'Redes Sociales', route: '/social-network-management' }],
					title: 'Otros',
				},
			],
		};
	},
	methods: {
		...mapActions(['logOut']),
		changeTypeUser() {
			const { typeUser } = this;
			this.$store.commit('setTypeUser', typeUser);
		},
		logOutAdmin() {
			this.logOut();
			this.$router.replace('/');
		},
		async getAdministrator() {
			try {
				const data = await ApiServicesAdmin.getAdministrator();
				this.administrator = data;
			} catch (error) {
				console.log('Error get administrator ' + error);
			}
		},
	},
	mounted() {
		this.getAdministrator();
	},
};
