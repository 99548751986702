import ApiServicesAdmin from '@/services/ApiServicesAdmin';
import alerts from '@/utilities/alerts';
export default {
	name: 'ImportantEventManagement',
	data() {
		return {
			title: 'Gestión Eventos Importantes',
			images: [],
			dialog: false,
			data: {
				image: {
					spanish: '',
					english: '',
				},
			},
			stateImageSpanish: true,
			stateImageEnglish: true,
		};
	},
	methods: {
		async getImagesEventsAdmin() {
			try {
				const data = await ApiServicesAdmin.getImagesEventsAdmin();
				this.images = data;
			} catch (error) {
				console.log('Error get images ' + error);
			}
		},
		closeModal() {
			this.dialog = false;
		},
		async save() {
			try {
				const { ...values } = { ...this.data };
				const formData = new FormData();
				formData.append('imageSpanish', values.image.spanish);
				formData.append('imageEnglish', values.image.english);
				const result = await ApiServicesAdmin.addImagesEvents(formData);
				alerts.showAlertAdd(result.result);
				this.closeModal();
			} catch (error) {
				console.log('Error add images event ' + error);
				this.closeModal();
			}
		},
		async deleteImage(value) {
			try {
				const result = await ApiServicesAdmin.deleteImagesEvents(value);
				alerts.showAlertDelete(result.result);
			} catch (error) {
				console.log('Error delete image ' + error);
			}
		},

		getPathImageSpanish(image) {
			if (image === '' || image === null) {
				this.stateImageSpanish = true;
			} else {
				this.data.image.spanish = image;
				this.stateImageSpanish = false;
			}
		},
		getPathImageEnglish(image) {
			if (image === '' || image === null) {
				this.stateImageEnglish = true;
			} else {
				this.data.image.english = image;
				this.stateImageEnglish = false;
			}
		},
	},
	watch: {
		images(newVal, oldVal) {
			this.getImagesEventsAdmin();
		},
	},
	mounted() {
		this.getImagesEventsAdmin();
	},
};
