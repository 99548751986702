import CardGroupLife from '@/components/user/CardGroupLife/CardGroupLife.vue';
import ApiServicesAdmin from '@/services/ApiServicesAdmin';
import alerts from '@/utilities/alerts';

export default {
	name: 'GroupLifeManagement',
	components: {
		CardGroupLife,
	},
	data() {
		return {
			title: 'Gestión Grupos De Vida',
			dialogEdit: true,
			dialog: false,
			e6: 1,
			groupsLife: [],
			page: 1,
			numCardsPerPage: 1,
			timeStart: null,
			modalitys: [
				{ name: 'Presencial', value: 1 },
				{ name: 'Virtual', value: 2 },
			],
			modalitySelected: null,
			inputSelect: true,
			checkSelect: true,
			timeSelect: true,
			data: {
				photo: '',
				name: {
					spanish: '',
					english: '',
				},
				details: {
					spanish: '',
					english: '',
				},
				modality: 0,
				address: '',
				days: [],
				hour: '',
				inCharge: '',
				contact: '',
			},
			regexNames: {
				spanish: /^[A-Za-zñÑáéíóúÁÉÍÓÚ\s]{5,30}$/,
				english: /^[A-Za-z\s]{5,30}$/,
			},
			regexDetails: {
				spanish: /^[A-Za-zñÑáéíóúÁÉÍÓÚ0-9\s?!¡¿.,:;"/]{5,1000}$/,
				english: /^[A-Za-z0-9\s?!¡¿.,:;"/]{5,1000}$/,
			},
			regexAddress: /^[A-Za-zñÑáéíóúÁÉÍÓÚ0-9\s?!¡¿.,:;"/]{5,100}$/,
			regexIncharge: /^[A-Za-zñÑáéíóúÁÉÍÓÚ0-9\s?!¡¿.,:;"/]{5,100}$/,
			regexContact: /^[A-Za-zñÑáéíóúÁÉÍÓÚ0-9\s?!¡¿.,:;"/]{5,100}$/,
			validateButton: true,
			daysSelected: [],
			daysChecks: [
				{ name: 'Lunes', value: 1 },
				{ name: 'Martes', value: 2 },
				{ name: 'Miércoles', value: 3 },
				{ name: 'Jueves', value: 4 },
				{ name: 'Viernes', value: 5 },
				{ name: 'Sábado', value: 6 },
				{ name: 'Domingo', value: 7 },
			],
		};
	},
	methods: {
		async getGroupsLife() {
			try {
				const data = await ApiServicesAdmin.getGroupsLife();
				this.groupsLife = data;
			} catch (error) {
				console.log('Error get data group life ' + error);
			}
		},
		edit(data) {
			this.dialog = true;
			this.data = JSON.parse(JSON.stringify(data));

			const mappedDays = this.data.days.map((day) => {
				const dayObject = this.daysChecks.find((check) => check.name === day);
				return dayObject.value;
			});
			this.daysSelected = mappedDays;
			this.modalitySelected = this.data.typeModality;
			this.timeStart = this.data.hour;
			this.dialogEdit = false;
			this.validateButton = false;
		},
		async remove(id) {
			try {
				const result = await ApiServicesAdmin.deleteGroupLife(id);
				alerts.showAlertDelete(result.result);
			} catch (error) {
				console.log('Error delete group life ' + error);
			}
		},
		closeModal() {
			this.dialog = false;
			this.validateButton = true;
			this.e6 = 1;
			this.data = {
				photo: '',
				name: {
					spanish: '',
					english: '',
				},
				details: {
					spanish: '',
					english: '',
				},
				modality: 0,
				address: '',
				days: [],
				hour: '',
				inCharge: '',
				contact: '',
			};
		},

		async save() {
			try {
				const formData = new FormData();
				const { ...values } = { ...this.data };
				formData.append('_id', values._id);
				formData.append('photo', values.photo);
				formData.append('name', JSON.stringify(values.name));
				formData.append('details', JSON.stringify(values.details));
				formData.append('modality', this.modalitySelected);
				formData.append('addressMeet', values.address);
				formData.append('days', this.daysSelected);
				formData.append('hour', this.timeStart);
				formData.append('inCharge', values.inCharge);
				formData.append('contact', values.contact);
				if (!values._id) {
					const result = await ApiServicesAdmin.addGroupLife(formData);
					alerts.showAlertAdd(result.result);
					this.closeModal();
				} else {
					const result = await ApiServicesAdmin.updateGroupLife(formData);
					alerts.showAlertUpdate(result.result);
					this.closeModal();
				}
			} catch (error) {
				console.log('Error save group life ' + error);
			}
		},
		getPathImage(image) {
			if ((image === '' || image === null) && this.dialogEdit) {
				this.validateButton = true;
			} else {
				this.data.photo = image;
				this.validateButton = false;
			}
		},
	},
	computed: {
		numPages() {
			return Math.ceil(this.groupsLife.length / this.numCardsPerPage);
		},
		visibleCards() {
			const start = (this.page - 1) * this.numCardsPerPage;
			const end = start + this.numCardsPerPage;
			return this.groupsLife.slice(start, end);
		},
		validateNames() {
			var nameSpanish = this.regexNames.spanish.test(this.data.name.spanish);
			var nameEnglish = this.regexNames.english.test(this.data.name.english);
			if (nameSpanish && nameEnglish) {
				return false;
			}
			return true;
		},
		validateAddress() {
			return this.regexAddress.test(this.data.address);
		},
		validateInCharge() {
			return !this.regexIncharge.test(this.data.inCharge);
		},
		validateContact() {
			return !this.regexContact.test(this.data.contact);
		},
		validateDetails() {
			var detailsSpanish = this.regexDetails.spanish.test(this.data.details.spanish);
			var detailsEnglish = this.regexDetails.english.test(this.data.details.english);
			if (detailsSpanish && detailsEnglish) {
				return false;
			}
			return true;
		},
	},
	watch: {
		groupsLife(newVal, oldVal) {
			this.getGroupsLife();
		},
		modalitySelected(newVal, oldVal) {
			const modality = JSON.parse(JSON.stringify(newVal));
			if (modality.name != '') {
				this.inputSelect = false;
			}
		},
		daysSelected(newVal, oldVal) {
			this.checkSelect = true;
			const days = JSON.parse(JSON.stringify(newVal));
			if (days.length > 0) {
				this.checkSelect = false;
			}
		},
		timeStart(newVal, oldVal) {
			const time = JSON.parse(JSON.stringify(newVal));
			if (time != null) {
				this.timeSelect = false;
			}
		},
	},
	mounted() {
		this.getGroupsLife();
	},
};
