import CardRegister from '@/components/user/CardRegister/CardRegister.vue';
import ApiServicesAdmin from '@/services/ApiServicesAdmin';
import alerts from '@/utilities/alerts';
export default {
	name: 'SectionRegister',
	components: {
		CardRegister,
	},
	data() {
		return {
			title: 'Actualizar Tarjetas de Registro',
			dialog: false,
			e6: 1,
			cards: [],
			page: 1,
			numCardsPerPage: 1,
			data: {
				image: {
					spanish: '',
					english: '',
				},
				title: {
					spanish: '',
					english: '',
				},
				subtitle: {
					spanish: '',
					english: '',
				},
				description: {
					spanish: '',
					english: '',
				},
				button: {
					spanish: 'Registrarse',
					english: 'Register',
				},
			},
			regexTitlesAndSubtitles: {
				spanish: /^[A-Za-zñÑáéíóúÁÉÍÓÚ\s?!¡¿]{5,50}$/,
				english: /^[A-Za-z\s?!¡¿]{5,50}$/,
			},
			regexDescriptions: {
				spanish: /^[A-Za-zñÑáéíóúÁÉÍÓÚ0-9\s?!¡¿.,:;"/]{5,500}$/,
				english: /^[A-Za-z0-9\s?!¡¿.,:;"/]{5,500}$/,
			},
		};
	},
	methods: {
		async getCardData() {
			try {
				const data = await ApiServicesAdmin.getDataCardRegister();
				this.cards = data;
			} catch (error) {
				console.log('Error get card informations ' + error);
			}
		},
		edit(data) {
			this.dialog = true;
			this.data = JSON.parse(JSON.stringify(data));
		},
		closeModal() {
			this.dialog = false;
			this.e6 = 1;
		},
		async save() {
			try {
				const formData = new FormData();
				const { ...values } = { ...this.data };
				formData.append('_id', values._id);
				formData.append('imageSpanish', values.image.spanish);
				formData.append('imageEnglish', values.image.english);
				formData.append('title', JSON.stringify(values.title));
				formData.append('subtitle', JSON.stringify(values.subtitle));
				formData.append('description', JSON.stringify(values.description));
				formData.append('button', JSON.stringify(values.button));
				const result = await ApiServicesAdmin.updateCardRegister(formData);
				alerts.showAlertUpdate(result.result);
				this.closeModal();
			} catch (error) {
				console.log('Error update card register ' + error);
				this.closeModal();
			}
		},
		getPathImageSpanish(imgSpanish) {
			this.data.image.spanish = imgSpanish;
		},
		getPathImageEnglish(imgEnglish) {
			this.data.image.english = imgEnglish;
		},
	},
	computed: {
		numPages() {
			return Math.ceil(this.cards.length / this.numCardsPerPage);
		},
		visibleCards() {
			const start = (this.page - 1) * this.numCardsPerPage;
			const end = start + this.numCardsPerPage;
			return this.cards.slice(start, end);
		},
		validateTitle() {
			var titleSpanish = this.regexTitlesAndSubtitles.spanish.test(this.data.title.spanish);
			var titleEnglish = this.regexTitlesAndSubtitles.english.test(this.data.title.english);
			if (titleSpanish && titleEnglish) {
				return false;
			}
			return true;
		},
		validateSubtitle() {
			var subtitleSpanish = this.regexTitlesAndSubtitles.spanish.test(
				this.data.subtitle.spanish
			);
			var subtitleEnglish = this.regexTitlesAndSubtitles.english.test(
				this.data.subtitle.english
			);
			if (subtitleSpanish && subtitleEnglish) {
				return false;
			}
			return true;
		},
		validateDescriptions() {
			var descriptionSpanish = this.regexDescriptions.spanish.test(
				this.data.description.spanish
			);
			var descriptionEnglish = this.regexDescriptions.english.test(
				this.data.description.english
			);
			if (descriptionSpanish && descriptionEnglish) {
				return false;
			}
			return true;
		},
	},
	watch: {
		cards(newVal, oldVal) {
			this.getCardData();
		},
	},
	mounted() {
		this.getCardData();
	},
};
