import ApiServices from '@/services/ApiServices';
import ApiServicesAdmin from '@/services/ApiServicesAdmin';
import alerts from '@/utilities/alerts';
export default {
	name: 'LiveEventManagement',
	data() {
		return {
			title: 'Actualizar evento en vivo',
			dialog: false,
			liveEvent: [],
			regexURL: /^https:\/\/www\.youtube\.com\//,
		};
	},
	methods: {
		async getLiveEvent() {
			try {
				const data = await ApiServices.getLiveEvent();
				this.liveEvent = data[0];
			} catch (error) {
				console.log('Error get live event ' + error);
			}
		},
		closeModal() {
			this.dialog = false;
		},
		async save() {
			try {
				const result = await ApiServicesAdmin.updateLiveEvent(this.liveEvent);
				alerts.showAlertUpdate(result);
				this.closeModal();
				this.getLiveEvent();
			} catch (error) {
				alerts.showAlertUpdate(result);
				this.closeModal();
			}
		},
		editLiveVideo(value) {
			this.dialog = true;
			this.liveEvent._id = value;
		},
	},
	computed: {
		validateURL() {
			return this.regexURL.test(this.liveEvent.src);
		},
	},
	mounted() {
		this.getLiveEvent();
	},
};
