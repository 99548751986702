import axios from 'axios';
import store from '@/store';

export const setAxiosInterceptors = () => {
	axios.interceptors.request.use((config) => {
		const accessToken = store.state.accessToken;
		if (accessToken) {
			config.headers.Authorization = accessToken;
		}
		return config;
	});
};
