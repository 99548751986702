export default {
	name: 'DonationQR',
	props: {
		_id: String,
		image: String,
		title: String,
		subtitle: String,
		address: String,
		data: {
			type: Object,
			default: () => ({
				_id: '',
				image: '',
				title: {
					spanish: '',
					english: '',
				},
				subtitle: {
					spanish: '',
					english: '',
				},
				address: '',
			}),
		},
	},
	data() {
		return {};
	},
	methods: {
		editCard() {
			this.$emit('edit', this.data);
		},
	},
};
