import ApiServices from '@/services/ApiServicesAdmin';
import alerts from '@/utilities/alerts';

export default {
	name: 'Missions',
	data() {
		return {
			title: 'Gestión Misiones',
			missions: [],
			dialog: false,
			dialogDelete: false,
			headers: [
				{ text: 'Inglés', value: 'mission.english' },
				{ text: 'Español', value: 'mission.spanish' },
				{ text: 'Acciones', value: 'actions', sortable: false },
			],
			editedIndex: -1,
			editedItem: {
				_id: '',
				mission: [
					{
						english: '',
						spanish: '',
					},
				],
			},
			defaultItem: {
				_id: '',
				mission: [
					{
						english: '',
						spanish: '',
					},
				],
			},
		};
	},
	methods: {
		async getMissions() {
			try {
				const data = await ApiServices.getMissions();
				this.missions = data;
			} catch (error) {
				console.log('Error get missions ' + error);
			}
		},
		async save() {
			try {
				if (this.editedIndex > -1) {
					const values = JSON.parse(JSON.stringify(this.editedItem));
					const result = await ApiServices.updateMission(values);
					alerts.showAlertUpdate(result.result);
				} else {
					const values = {
						mission: {
							spanish: this.editedItem.mission.spanish,
							english: this.editedItem.mission.english,
						},
					};
					const result = await ApiServices.addMission(values);
					alerts.showAlertAdd(result.result);
				}
				this.close();
			} catch (error) {
				console.log('Error save new mission ' + error);
			}
		},
		editItem(item) {
			const values = JSON.parse(JSON.stringify(item));
			this.editedIndex = this.missions.indexOf(item);
			this.editedItem = Object.assign({}, values);
			this.dialog = true;
		},
		close() {
			this.dialog = false;
			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem);
				this.editedIndex = -1;
			});
		},
		closeDelete() {
			this.dialogDelete = false;
			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem);
				this.editedIndex = -1;
			});
		},
		deleteItem(item) {
			const values = JSON.parse(JSON.stringify(item));
			this.editedIndex = this.missions.indexOf(item);
			this.editedItem = Object.assign({}, values);
			this.dialogDelete = true;
		},
		async deleteItemConfirm() {
			const value = JSON.parse(JSON.stringify(this.editedItem));
			const result = await ApiServices.deleteMission(value._id);
			alerts.showAlertDelete(result.result);
			this.closeDelete();
		},
		activeButton() {
			if (this.validateMissionSpanish && this.validateMissionEnglish) {
				return false;
			}
			return true;
		},
	},
	computed: {
		formTitle() {
			return this.editedIndex === -1 ? 'Agregar Misión' : 'Editar Misión';
		},
		validateMissionSpanish() {
			if (typeof this.editedItem.mission.spanish === 'undefined') {
				return false;
			} else {
				return /^[A-Za-zñÑáéíóúÁÉÍÓÚ\s]{5,50}$/.test(this.editedItem.mission.spanish);
			}
		},
		validateMissionEnglish() {
			if (typeof this.editedItem.mission.english === 'undefined') {
				return false;
			} else {
				return /^[A-Za-z\s]{5,50}$/.test(this.editedItem.mission.english);
			}
		},
	},
	watch: {
		missions(newVal, oldVal) {
			this.getMissions();
		},
		dialog(val) {
			val || this.close();
		},
		dialogDelete(val) {
			val || this.closeDelete();
		},
	},
	mounted() {
		this.getMissions();
	},
	created() {
		this.getMissions();
	},
};
