<template>
	<SectionDonation />
</template>
<script>
	import SectionDonation from '@/components/user/SectionDonation/SectionDonation.vue';
	export default {
		name: 'DonationView',
		components: {
			SectionDonation,
		},
	};
</script>
