import axios from 'axios';
const path = 'https://icnbakersfield.com:3336';
export default {
	async getMenuOptions(language) {
		const { data } = await axios.get(`${path}/menu/${language}`);
		return data;
	},
	async getVideoPresentation() {
		const { data } = await axios.get(`${path}/video-presentation/`);
		return data[0];
	},
	async getCardRegister(language) {
		const { data } = await axios.get(`${path}/cards-register/${language}`);
		return data;
	},
	async getPastoralTeam(language) {
		const { data } = await axios.get(`${path}/user-pastoral/${language}`);
		return data;
	},
	async getSeniorPastor(language) {
		const { data } = await axios.get(`${path}/senior-pastor/${language}`);
		return data;
	},
	async getDataFooter(language) {
		const { data } = await axios.get(`${path}/footer/${language}`);
		return data;
	},
	async getSocialNetworks() {
		const { data } = await axios.get(`${path}/social-network`);
		return data;
	},
	async getMissions(language) {
		const { data } = await axios.get(`${path}/missions/${language}`);
		return data;
	},
	async getCoreValues(language) {
		const { data } = await axios.get(`${path}/core-value/${language}`);
		return data;
	},
	async getEvents(language) {
		const { data } = await axios.get(`${path}/events/${language}`);
		return data;
	},
	async getLiveEvent() {
		const { data } = await axios.get(`${path}/live-event`);
		return data;
	},
	async getServices(language) {
		const { data } = await axios.get(`${path}/services/${language}`);
		return data;
	},
	async addNewUser(infor) {
		console.log(infor);
		const { data } = await axios.post(`${path}/register/new-user/`, infor);
		return data;
	},
	async getGroupsLife(language) {
		const { data } = await axios.get(`${path}/group-life/${language}`);
		return data;
	},
	async getPageDonation(amount) {
		const { data } = await axios.get(`${path}/payment/${amount}`);
		return data;
	},
	async getInformationCardDonation(language) {
		const { data } = await axios.get(`${path}/donation/${language}`);
		return data[0];
	},
	async getDonationQR(language) {
		const { data } = await axios.get(`${path}/donation-qr/${language}`);
		return data[0];
	},
	async getWayDonation(language) {
		const { data } = await axios.get(`${path}/donation-way/${language}`);
		return data;
	},
};
