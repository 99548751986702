import CardWayDonation from '@/components/user/CardWayDonation/CardWayDonation.vue';
import ApiServicesAdmin from '@/services/ApiServicesAdmin';
import alerts from '@/utilities/alerts';
export default {
	name: 'CardDonationPaypalManagement',
	components: {
		CardWayDonation,
	},
	data() {
		return {
			title: 'OTROS TIPOS DE DONACIONES',
			dialog: false,
			e6: 1,
			information: [],
			data: {
				title: {
					spanish: '',
					english: '',
				},
				description: {
					spanish: '',
					english: '',
				},
			},
			regexTitle: {
				spanish: /^[A-Za-zñÑáéíóúÁÉÍÓÚ\s]{5,30}$/,
				english: /^[A-Za-z\s]{5,30}$/,
			},
			regexDescription: {
				spanish: /^[A-Za-zñÑáéíóúÁÉÍÓÚ0-9\s?!¡¿.,:;"/]{5,1000}$/,
				english: /^[A-Za-z0-9\s?!¡¿.,:;"/]{5,1000}$/,
			},
		};
	},
	methods: {
		async getInformation() {
			try {
				const data = await ApiServicesAdmin.getDonationWays();
				this.information = data;
			} catch (error) {
				console.log('Error get data card donation admin ' + error);
			}
		},
		edit(data) {
			this.dialog = true;
			this.data = JSON.parse(JSON.stringify(data));
		},
		closeModal() {
			this.dialog = false;
			this.e6 = 1;
			this.data = {
				title: {
					spanish: '',
					english: '',
				},
				description: {
					spanish: '',
					english: '',
				},
			};
		},
		async save() {
			try {
				const values = JSON.parse(JSON.stringify(this.data));
				const result = await ApiServicesAdmin.updateDonationWay(values);
				alerts.showAlertUpdate(result.result);
				this.closeModal();
			} catch (error) {
				console.log('Error update card donation ' + error);
				this.closeModal();
			}
		},
	},
	computed: {
		validateTitles() {
			var titleSpanish = this.regexTitle.spanish.test(this.data.title.spanish);
			var titleEnglish = this.regexTitle.english.test(this.data.title.english);
			if (titleSpanish && titleEnglish) {
				return false;
			}
			return true;
		},
		validateDescriptions() {
			var descriptionSpanish = this.regexDescription.spanish.test(
				this.data.description.spanish
			);
			var descriptionEnglish = this.regexDescription.english.test(
				this.data.description.english
			);
			if (descriptionSpanish && descriptionEnglish) {
				return false;
			}
			return true;
		},
	},
	watch: {
		information(newVal, oldVal) {
			this.getInformation();
		},
	},
	mounted() {
		this.getInformation();
	},
};
