<template>
	<v-app class="container-bg">
		<div>
			<MenuBar v-if="this.$store.state.typeUser == 'admin'" />
			<Toolbar v-if="showToolbarFooter" />
			<v-container>
				<router-view />
			</v-container>
			<Footer v-if="showToolbarFooter" />
		</div>
	</v-app>
</template>
<style>
	.container-bg {
		background-color: #111827 !important;
	}
</style>

<script>
	import Toolbar from './components/user/ToolBar/Toolbar.vue';
	import Footer from './components/user/Footer/Footer.vue';
	import MenuBar from './components/admin/MenuBar/MenuBar.vue';
	export default {
		name: 'App',
		components: { Toolbar, Footer, MenuBar },
		computed: {
			showToolbarFooter() {
				return this.$route.path !== '/login' && this.$store.state.typeUser === 'user';
			},
		},
		watch: {
			'$route.path': function () {
				if (this.$route.path === '/admin') {
					this.$store.state.typeUser = 'admin';
				}
			},
		},
	};
</script>
