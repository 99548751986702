export default {
	name: 'CardPastoralTeam',
	props: {
		_id: String,
		photo: String,
		description: String,
		name: String,
		lastname: String,
		position: String,
		type: Number,
		data: {
			type: Object,
			default: () => ({
				_id: '',
				photo: '',
				description: {
					spanish: '',
					english: '',
				},
				name: '',
				lastname: '',
				position: {
					spanish: '',
					english: '',
				},
				type: 0,
			}),
		},
	},
	methods: {
		openModal() {
			this.$emit('openModal', this.modalId);
		},
		editCard() {
			this.$emit('edit', this.data);
		},
		deleteCard() {
			this.$emit('remove', this.data._id);
		},
	},
};
