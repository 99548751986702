<template>
	<div class="about-us">
		<SectionAboutUs />
	</div>
</template>
<script>
	import SectionAboutUs from '@/components/user/SectionAboutUs/SectionAboutUs.vue';
	export default {
		name: 'AboutUserView',
		components: {
			SectionAboutUs,
		},
		data() {
			return {};
		},
	};
</script>
