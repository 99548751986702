import CardPastoralTeam from '../CardPastoralTeam/CardPastoralTeam.vue';
import ApiServices from '@/services/ApiServices';
import { mapState } from 'vuex';
export default {
	name: 'SectionAboutUs',
	components: {
		CardPastoralTeam,
	},
	data() {
		return {
			missions: [],
			coreValues: [],
			seniorPastor: [],
			teamPastoral: [],
			titlesSections: {
				spanish: {
					mission: 'MISIÓN',
					coreValues: 'VALORES CENTRALES',
					whoIAm: '¿QUIÉN SOY?',
					pastoralTeam: 'EQUIPO PASTORAL',
				},
				english: {
					mission: 'MISSION',
					coreValues: 'CORE VALUES',
					whoIAm: 'WHO I AM?',
					pastoralTeam: 'PASTORAL TEAM',
				},
			},
			titlesSelecteds: [],
		};
	},
	methods: {
		async getMissions() {
			try {
				const data = await ApiServices.getMissions(this.$store.state.language);
				this.missions = data;
			} catch (error) {
				console.log('Error get missions ' + error);
			}
		},
		async getCoreValues() {
			try {
				const data = await ApiServices.getCoreValues(this.$store.state.language);
				this.coreValues = data;
			} catch (error) {
				console.log('Error get missions ' + error);
			}
		},
		async getSeniorPastor() {
			try {
				const data = await ApiServices.getSeniorPastor(this.$store.state.language);
				this.seniorPastor = data[0];
			} catch (error) {
				console.log('Error get team pastoral ' + error);
			}
		},
		async getPastoralTeam() {
			try {
				const data = await ApiServices.getPastoralTeam(this.$store.state.language);
				this.teamPastoral = data;
			} catch (error) {
				console.log('Error get team pastoral ' + error);
			}
		},
		selectTitlesByLanguage() {
			try {
				const language = this.$store.state.language;
				this.titlesSelecteds = this.titlesSections[language];
			} catch (error) {
				console.log('Error select title by language ' + error);
			}
		},
	},
	computed: {
		...mapState({
			language: (state) => state.language,
		}),
	},
	watch: {
		language: {
			handler() {
				this.getSeniorPastor();
				this.getPastoralTeam();
				this.getMissions();
				this.getCoreValues();
				this.selectTitlesByLanguage();
			},
		},
	},
	mounted() {
		this.getSeniorPastor();
		this.getPastoralTeam();
		this.getMissions();
		this.getCoreValues();
		this.selectTitlesByLanguage();
	},
};
