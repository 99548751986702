import ApiServicesAdmin from '@/services/ApiServicesAdmin';
import alerts from '@/utilities/alerts';
import encryptPassword from '@/utilities/EncriptPassword';
import { mapActions } from 'vuex';
export default {
	name: 'ApplicationAdminView',
	data() {
		return {
			details: [],
			generalInformation: [],
			dialogGeneralInformation: false,
			dialogAdministratorInformation: false,
			administratorInformation: [],
			dialogPassword: false,
			oldPassword: '',
			newPassword: '',
			repetPassword: '',
			passwordAdministrator: '',
			checkOldPassword: false,
			checkEqualsPassword: false,
			showOldPassword: false,
			showNewPassword: false,
			showRepetPassword: false,
		};
	},
	methods: {
		async getDetailsRegister() {
			try {
				const data = await ApiServicesAdmin.getDetailsRegisters();
				this.details = data;
			} catch (error) {
				console.log('Error get details register ' + error);
			}
		},
		async getGeneralInformation() {
			try {
				const data = await ApiServicesAdmin.getGeneralInformation();
				this.generalInformation = data;
			} catch (error) {
				console.log('Error get general information ' + error);
			}
		},
		editGeneralInformation() {
			this.dialogGeneralInformation = true;
		},
		async saveGeneralInformation() {
			try {
				const result = await ApiServicesAdmin.updateGeneralInformation(
					this.generalInformation
				);
				alerts.showAlertUpdate(result.result);
				this.closeModalGeneralInformation();
			} catch (error) {
				console.log('Error update general information ' + error);
			}
		},
		closeModalGeneralInformation() {
			this.dialogGeneralInformation = false;
		},
		async getInforAdministrator() {
			try {
				const data = await ApiServicesAdmin.getAdministrator();
				this.administratorInformation = data;
			} catch (error) {
				console.log('Error get information administrator ' + error);
			}
		},
		editAdministratorInformation() {
			this.dialogAdministratorInformation = true;
		},
		async saveAdministratorInformation() {
			try {
				const result = await ApiServicesAdmin.updateAdministratorInformation(
					this.administratorInformation
				);
				alerts.showAlertUpdate(result.result);
				this.closeModalAdministratorInformation();
			} catch (error) {
				console.log('Error update administrator information ' + error);
			}
		},
		closeModalAdministratorInformation() {
			this.dialogAdministratorInformation = false;
		},
		changedPassword() {
			this.dialogPassword = true;
			this.getPasswordAdministrator();
		},
		async getPasswordAdministrator() {
			try {
				const result = await ApiServicesAdmin.getPasswordAdminitrator();
				this.passwordAdministrator = result.password;
			} catch (error) {
				console.log('Error get password administrator ' + error);
			}
		},
		async saveChangedPassword() {
			const _id = this.administratorInformation._id;
			const infor = {
				id: _id,
				password: encryptPassword.encriptPassword(this.newPassword),
			};
			const result = await ApiServicesAdmin.updatePasswordAdministrator(infor);
			alerts.showAlertUpdate(result.result);
			if (result.result) this.logOutAdmin();
			this.closeModalPassword();
		},
		closeModalPassword() {
			this.dialogPassword = false;
			this.oldPassword = '';
			this.newPassword = '';
			this.repetPassword = '';
		},
		...mapActions(['logOut']),
		logOutAdmin() {
			this.logOut();
			this.$router.replace('/');
		},
	},
	computed: {
		validateGeneralInformation() {
			return this.dialogGeneralInformation.name === '' ? true : false;
		},
		validateNewPassword() {
			return /^(?=.*[A-Z])(?=.*[0-9])(?=.*[\W_]).{8,}$/.test(this.newPassword);
		},
		validEqualPasswords() {
			return this.newPassword === this.repetPassword ? true : false;
		},
	},
	watch: {
		oldPassword(newVal, oldVal) {
			const passwordEncript = encryptPassword.encriptPassword(newVal);
			this.checkOldPassword = passwordEncript === this.passwordAdministrator ? true : false;
		},
	},
	mounted() {
		this.getDetailsRegister();
		this.getGeneralInformation();
		this.getInforAdministrator();
	},
	created() {
		this.getDetailsRegister();
		this.getGeneralInformation();
		this.getInforAdministrator();
	},
};
