const Swal = require('sweetalert2');
export default {
	showAlertAdd(result) {
		if (result) {
			this.alertSuccessAdd();
			return;
		}
		this.alertErrorAdd();
	},
	showAlertUpdate(result) {
		if (result) {
			this.alertSuccessUpdate();
			return;
		}
		this.alertErrorUpdate();
	},
	showAlertDelete(result) {
		if (result) {
			this.alertSuccessDeleted();
			return;
		}
		this.alertErrorDeleted();
	},

	showAlertRegister(title, result) {
		if (result) {
			this.alertSuccessRegister(title);
			return;
		}
		this.alertErrorRegister(title);
	},
	alertSuccessAdd() {
		Swal.fire({
			position: 'top-end',
			icon: 'success',
			title: 'Agregado correctamente',
			showConfirmButton: false,
			timer: 1500,
		});
	},

	alertErrorAdd() {
		Swal.fire({
			position: 'top-end',
			icon: 'error',
			title: 'Error al agregar',
			showConfirmButton: false,
			timer: 1500,
		});
	},

	alertSuccessUpdate() {
		Swal.fire({
			position: 'top-end',
			icon: 'success',
			title: 'Actualizado correctamente',
			showConfirmButton: false,
			timer: 1500,
		});
	},

	alertErrorUpdate() {
		Swal.fire({
			position: 'top-end',
			icon: 'error',
			title: 'Error al actualizar',
			showConfirmButton: false,
			timer: 1500,
		});
	},

	alertSuccessDeleted() {
		Swal.fire({
			position: 'top-end',
			icon: 'success',
			title: 'Eliminado correctamente',
			showConfirmButton: false,
			timer: 1500,
		});
	},
	alertErrorDeleted() {
		Swal.fire({
			position: 'top-end',
			icon: 'error',
			title: 'Error al eliminar',
			showConfirmButton: false,
			timer: 1500,
		});
	},
	alertSuccessRegister(titleAlert) {
		Swal.fire({
			position: 'top-end',
			icon: 'success',
			title: titleAlert,
			showConfirmButton: false,
			timer: 1500,
		});
	},
	alertErrorRegister(titleAlert) {
		Swal.fire({
			position: 'top-end',
			icon: 'error',
			title: titleAlert,
			showConfirmButton: false,
			timer: 1500,
		});
	},
	alertAmountEmpty(msgAlert) {
		Swal.fire({
			position: 'top-end',
			icon: 'error',
			title: msgAlert,
			showConfirmButton: false,
			timer: 1500,
		});
	},
	alertAmountIncorrect(msgAlert) {
		Swal.fire({
			position: 'top-end',
			icon: 'error',
			title: msgAlert,
			showConfirmButton: false,
			timer: 1500,
		});
	},
	alertRedirectDonation(url, msgAlert) {
		let timerInterval;
		Swal.fire({
			title: msgAlert,
			timer: 2500,
			timerProgressBar: true,
			didOpen: () => {
				Swal.showLoading();
				timerInterval = setInterval(() => {}, 100);
			},
			willClose: () => {
				clearInterval(timerInterval);
			},
		}).then((result) => {
			if (result.dismiss === Swal.DismissReason.timer) {
				window.open(url, '_blank');
			}
		});
	},
};
