<template>
	<div class="home">
		<div class="sectionPresentation">
			<SectionPresentation />
		</div>
		<div class="register">
			<SectionRegister />
		</div>
		<div class="notice">
			<SectionMultimedia />
		</div>
	</div>
</template>
<style></style>

<script>
	import SectionPresentation from '@/components/user/SectionPresentation/SectionPresentation.vue';
	import SectionRegister from '@/components/user/SectionRegister/SectionRegister.vue';
	import SectionMultimedia from '@/components/user/SectionMultimedia/SectionMultimedia.vue';
	export default {
		name: 'HomeUserView',
		components: {
			SectionPresentation,
			SectionRegister,
			SectionMultimedia,
		},
	};
</script>
