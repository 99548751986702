import './main.css';

import Vue from 'vue';

import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router';
import store from './store';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

Vue.config.productionTip = false;

new Vue({
	router,
	store,
	vuetify,
	VueSweetalert2,
	render: (h) => h(App),
}).$mount('#app');
